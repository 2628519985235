import * as React from 'react';
import {
  useStyles2,
  useTheme2,
  Form,
  InlineField,
  HorizontalGroup,
  Button,
  Select,
  RadioButtonGroup,
  Input,
  Icon,
  ConfirmModal,
  InputControl,
} from '@grafana/ui';
import { getStyles } from './getSectionsStyles';
import { AppContext } from 'components/SimplePanel';
import { handleFftSensorTypeChange, handleModeChange, handleSpectrumTypeChange, validateFft } from 'utils/formEvents';
import {
  ACCELERO_MAX_FREQ,
  MAX_CUTOFF_VALUE,
  MICRO_MAX_FREQ,
  MIN_INTERVAL_ACCEL,
  MIN_INTERVAL_MICRO,
} from 'utils/constants';
import { getResolution } from 'utils/helpers';
import { useSettingsFft } from 'hooks/useSettingsFft';
import { useFormFFTZoomSettings } from 'hooks/useFormFFTZoomSettings';

export const SectionFftZoomSettings: React.FunctionComponent = () => {
  const { dico, panelData } = React.useContext(AppContext);
  const {
    submitSettings,
    resetText,
    settingsFft,
    frequencyMax,
    frequencyMin,
    periodicity,
    sensor,
    spectrum,
    fftSignatures,
    fftAmount,
    type,
    orientation,
    mode,
    cutoffValue,
    resolution,
    resolutionTooLow,
    fieldRequired,
    submitFftZoomSettingsTxt,
    sendConfirmationMessage,
    settingsNotReceived,
  } = dico?.dico || {};

  const theme = useTheme2();
  const styles = useStyles2(() => getStyles(theme));
  const settings = useSettingsFft(panelData, dico.dico);

  const {
    PERIODICITY_MODES_OPTIONS,
    PERIODICITY_STEPS_OPTIONS,
    SENSOR_TYPES_OPTIONS,
    ORIENTATION_OPTIONS,
    COMPRESSION_OPTIONS,
    SPECTRUM_TYPES_MICRO,
    SPECTRUM_TYPES_ACCELERO,
    isCurrentFFTEqualToCurrentSettings,
    checkFftSettings,
    setShowConfirmModal,
    showConfirmModal,
    submitSettingsForm,
  } = useFormFFTZoomSettings();

  return (
    <>
      {settings && Object.keys(settings.display).length && (
        <div className={styles.section}>
          <Form
            defaultValues={settings.display}
            onSubmit={(form) => checkFftSettings(form)}
            validateOn={'onSubmit'}
            noValidate>
            {({ control, watch, register, errors, getValues, setValue, setError, clearErrors, reset }) => {
              const isSubmitDisabled =
                !!Object.keys(errors).length ||
                (watch('fft_sensor_type') === '12'
                  ? getResolution(getValues, watch) < 50
                  : getResolution(getValues, watch) < 1);

              return (
                <>
                  <h4>{settingsFft?.toUpperCase()}</h4>
                  {/* Periodicity mode */}
                  <h5>{periodicity}</h5>
                  <InlineField label={mode} labelWidth={24} htmlFor="activation">
                    <InputControl
                      render={({ field: { ref, ...field } }) => (
                        <RadioButtonGroup
                          {...field}
                          options={PERIODICITY_MODES_OPTIONS}
                          id="activation"
                          onChange={(e) => handleModeChange(e, setValue, clearErrors)}
                        />
                      )}
                      control={control}
                      name="activation"
                    />
                  </InlineField>
                  {watch('activation') !== 0 && (
                    <>
                      {/* Steps for periodic mode */}
                      {watch('activation') === 1 && (
                        <InlineField label={fftSignatures} labelWidth={24} htmlFor="periodicSteps">
                          <InputControl
                            render={({ field: { ref, ...field } }) => (
                              <Select
                                {...field}
                                onChange={(e) => setValue('steps', e.value)}
                                options={PERIODICITY_STEPS_OPTIONS}
                                prefix={'1/'}
                                inputId="periodicSteps"
                                width={16}
                              />
                            )}
                            control={control}
                            name="steps"
                          />
                        </InlineField>
                      )}
                      {/* Steps for burst mode */}
                      {watch('activation') === 2 && (
                        <InlineField
                          label={fftAmount}
                          labelWidth={24}
                          htmlFor="burst_steps"
                          tooltip={`Maximum 200`}
                          invalid={!!errors.steps}
                          error={errors.steps?.message}>
                          <InputControl
                            render={({ field, fieldState: { invalid } }) => (
                              <Input
                                {...field}
                                {...register('steps', { required: fieldRequired })}
                                required
                                invalid={invalid}
                                min={1}
                                max={200}
                                id="burst_steps"
                                type={'number'}
                                width={16}
                                onBlur={(e) =>
                                  setValue(
                                    'steps',
                                    parseInt(e.currentTarget.value.toString()?.replace(/^0+(?=\d)/, ''), 10)
                                  )
                                }
                                onChange={(e) =>
                                  validateFft(
                                    'steps',
                                    e.currentTarget.value,
                                    setValue,
                                    setError,
                                    clearErrors,
                                    getValues,
                                    dico.dico
                                  )
                                }
                              />
                            )}
                            control={control}
                            name="steps"
                          />
                        </InlineField>
                      )}
                      {/* Sensor */}
                      <h5>{sensor}</h5>
                      {/* Sensor type */}
                      <InlineField label={type} labelWidth={24} htmlFor="fft_sensor_type">
                        <InputControl
                          render={({ field: { ref, ...field } }) => (
                            <RadioButtonGroup
                              {...field}
                              onChange={(e) => handleFftSensorTypeChange(e, setValue, clearErrors)}
                              options={SENSOR_TYPES_OPTIONS}
                              id="fft_sensor_type"
                            />
                          )}
                          control={control}
                          name="fft_sensor_type"
                        />
                      </InlineField>
                      {/* Sensor orientation*/}
                      {watch('fft_sensor_type') !== '12' && (
                        <InlineField label={orientation} labelWidth={24} htmlFor="fftSensorOrientation">
                          <InputControl
                            render={({ field: { ref, ...field } }) => (
                              <Select
                                {...field}
                                onChange={(e) => setValue('fft_sensor_orientation', e.value)}
                                options={ORIENTATION_OPTIONS}
                                inputId="fftSensorOrientation"
                                width={16}
                              />
                            )}
                            control={control}
                            name="fft_sensor_orientation"
                          />
                        </InlineField>
                      )}
                      <h5>{spectrum}</h5>
                      {/* Spectrum */}
                      {/* Accelerometer freq */}
                      {watch('fft_sensor_type') === '3' && (
                        <>
                          {/* Accelerometer min freq */}
                          <InlineField
                            label={frequencyMin}
                            labelWidth={24}
                            htmlFor="accFreqMinFft"
                            required
                            invalid={!!errors.fft_freq_min}
                            error={errors.fft_freq_min?.message}>
                            <InputControl
                              render={({ field, fieldState: { invalid } }) => (
                                <Input
                                  {...field}
                                  {...register('fft_freq_min', {
                                    required: fieldRequired,
                                  })}
                                  invalid={invalid}
                                  id="accFreqMinFft"
                                  type={'number'}
                                  min={0}
                                  max={ACCELERO_MAX_FREQ - MIN_INTERVAL_ACCEL}
                                  width={16}
                                  suffix={'Hz'}
                                  onBlur={(e) =>
                                    setValue('fft_freq_min', e.currentTarget.value?.replace(/^0+(?=\d)/, ''))
                                  }
                                  onChange={(e) =>
                                    validateFft(
                                      'fft_freq_min',
                                      e.currentTarget.value,
                                      setValue,
                                      setError,
                                      clearErrors,
                                      getValues,
                                      dico.dico
                                    )
                                  }
                                />
                              )}
                              control={control}
                              name="fft_freq_min"
                            />
                          </InlineField>
                          {/*Accelerometer max freq */}
                          <InlineField
                            label={frequencyMax}
                            labelWidth={24}
                            htmlFor="accfreqMaxFft"
                            tooltip={`Maximum ${ACCELERO_MAX_FREQ}Hz`}
                            required
                            invalid={!!errors.fft_freq_max}
                            error={errors.fft_freq_max?.message}>
                            <InputControl
                              render={({ field, fieldState: { invalid } }) => (
                                <Input
                                  {...field}
                                  invalid={invalid}
                                  {...register('fft_freq_max', {
                                    required: fieldRequired,
                                  })}
                                  id="accfreqMaxFft"
                                  type={'number'}
                                  min={MIN_INTERVAL_ACCEL}
                                  width={16}
                                  suffix={'Hz'}
                                  max={ACCELERO_MAX_FREQ}
                                  onBlur={(e) =>
                                    setValue('fft_freq_max', e.currentTarget.value?.replace(/^0+(?=\d)/, ''))
                                  }
                                  onChange={(e) =>
                                    validateFft(
                                      'fft_freq_max',
                                      e.currentTarget.value,
                                      setValue,
                                      setError,
                                      clearErrors,
                                      getValues,
                                      dico.dico
                                    )
                                  }
                                />
                              )}
                              control={control}
                              name="fft_freq_max"
                            />
                          </InlineField>
                        </>
                      )}
                      {/* Microphone freq */}
                      {watch('fft_sensor_type') === '12' && (
                        <>
                          {/* Microphone min freq */}
                          <InlineField
                            label={frequencyMin}
                            labelWidth={24}
                            htmlFor="micFreqMin"
                            required
                            invalid={!!errors.fft_freq_min}
                            error={errors.fft_freq_min?.message}>
                            <InputControl
                              render={({ field, fieldState: { invalid } }) => (
                                <Input
                                  {...field}
                                  {...register('fft_freq_min', {
                                    required: fieldRequired,
                                  })}
                                  invalid={invalid}
                                  id="micFreqMin"
                                  type={'number'}
                                  width={16}
                                  suffix={'Hz'}
                                  min={0}
                                  onBlur={(e) =>
                                    setValue('fft_freq_min', e.currentTarget.value?.replace(/^0+(?=\d)/, ''))
                                  }
                                  onChange={(e) =>
                                    validateFft(
                                      'fft_freq_min',
                                      e.currentTarget.value,
                                      setValue,
                                      setError,
                                      clearErrors,
                                      getValues,
                                      dico.dico
                                    )
                                  }
                                />
                              )}
                              control={control}
                              name="fft_freq_min"
                            />
                          </InlineField>
                          {/*Microphone max freq */}
                          <InlineField
                            label={frequencyMax}
                            labelWidth={24}
                            htmlFor="micfreqMaxFft"
                            required
                            invalid={!!errors.fft_freq_max}
                            error={errors.fft_freq_max?.message}
                            tooltip={`Maximum ${MICRO_MAX_FREQ}Hz`}>
                            <InputControl
                              render={({ field, fieldState: { invalid } }) => (
                                <Input
                                  {...field}
                                  invalid={invalid}
                                  {...register('fft_freq_max', {
                                    required: fieldRequired,
                                  })}
                                  id="micfreqMaxFft"
                                  type={'number'}
                                  min={MIN_INTERVAL_MICRO}
                                  width={16}
                                  suffix={'Hz'}
                                  max={MICRO_MAX_FREQ}
                                  onBlur={(e) =>
                                    setValue('fft_freq_max', e.currentTarget.value?.replace(/^0+(?=\d)/, ''))
                                  }
                                  onChange={(e) =>
                                    validateFft(
                                      'fft_freq_max',
                                      e.currentTarget.value,
                                      setValue,
                                      setError,
                                      clearErrors,
                                      getValues,
                                      dico.dico
                                    )
                                  }
                                />
                              )}
                              control={control}
                              name="fft_freq_max"
                            />
                          </InlineField>
                        </>
                      )}
                      {/* Compression */}
                      <InlineField label={'Compression'} labelWidth={24} htmlFor="fftCompression">
                        <InputControl
                          render={({ field: { ref, ...field } }) => (
                            <Select
                              {...field}
                              onChange={(e) => setValue('fft_compression', e.value)}
                              options={COMPRESSION_OPTIONS}
                              inputId="fftCompression"
                              width={40}
                            />
                          )}
                          control={control}
                          name="fft_compression"
                        />
                      </InlineField>
                      {/* Computed resolution */}
                      {/* for microphone  */}
                      {watch('fft_sensor_type') === '12' && (
                        <>
                          {getResolution(getValues, watch) < 50 && (
                            <div
                              style={{
                                padding: '8px 0',
                                color: 'red',
                              }}>
                              <Icon name={'times'} /> {resolutionTooLow} {getResolution(getValues, watch)}
                              Hz (min 50Hz)
                            </div>
                          )}
                          {getResolution(getValues, watch) >= 50 && (
                            <div
                              style={{
                                padding: '8px 0',
                                color: 'green',
                              }}>
                              <Icon name={'check'} /> {resolution} {getResolution(getValues, watch)}
                              Hz
                            </div>
                          )}
                        </>
                      )}
                      {/* for accelerometer  */}
                      {watch('fft_sensor_type') === '3' && (
                        <>
                          {getResolution(getValues, watch) < 1 && (
                            <div
                              style={{
                                padding: '8px 0',
                                color: 'red',
                              }}>
                              <Icon name={'times'} /> {resolutionTooLow} {getResolution(getValues, watch)}
                              Hz (min 1Hz)
                            </div>
                          )}
                          {getResolution(getValues, watch) >= 1 && (
                            <div
                              style={{
                                padding: '8px 0',
                                color: 'green',
                              }}>
                              <Icon name={'check'} /> {resolution} {getResolution(getValues, watch)}
                              Hz
                            </div>
                          )}
                        </>
                      )}
                      {/* Spectrum type options */}
                      <InlineField label={'Spectrum type'} labelWidth={24} htmlFor="fftSpectrType">
                        <InputControl
                          render={({ field: { ref, ...field } }) => (
                            <Select
                              {...field}
                              onChange={(e) => handleSpectrumTypeChange(e.value, setValue, clearErrors)}
                              options={
                                watch('fft_sensor_type') === '12' ? SPECTRUM_TYPES_MICRO : SPECTRUM_TYPES_ACCELERO
                              }
                              inputId="fftSpectrType"
                              menuPlacement="top"
                              width={40}
                            />
                          )}
                          control={control}
                          name="fft_spectr_type"
                        />
                      </InlineField>
                      {(watch('fft_spectr_type') === '5' || watch('fft_spectr_type') === '6') && (
                        <InlineField
                          label={cutoffValue}
                          labelWidth={24}
                          htmlFor="cutoff"
                          invalid={!!errors.fft_cutoff}
                          error={errors.fft_cutoff?.message}
                          tooltip={`Maximum ${MAX_CUTOFF_VALUE}Hz`}>
                          <InputControl
                            render={({ field, fieldState: { invalid } }) => (
                              <Input
                                {...field}
                                invalid={invalid}
                                {...register('fft_cutoff', {
                                  required: fieldRequired,
                                })}
                                id="cutoff"
                                type={'number'}
                                width={16}
                                suffix={'Hz'}
                                min={0}
                                max={MAX_CUTOFF_VALUE}
                                onBlur={(e) => setValue('fft_cutoff', e.currentTarget.value?.replace(/^0+(?=\d)/, ''))}
                                onChange={(e) =>
                                  validateFft(
                                    'fft_cutoff',
                                    e.currentTarget.value,
                                    setValue,
                                    setError,
                                    clearErrors,
                                    getValues,
                                    dico.dico
                                  )
                                }
                              />
                            )}
                            control={control}
                            name="fft_cutoff"
                          />
                        </InlineField>
                      )}
                    </>
                  )}
                  {/* Confirmation modal */}
                  <div className={styles.submit}>
                    <HorizontalGroup spacing={'sm'} justify={'flex-start'} align={'center'}>
                      <Button type="reset" variant={'secondary'} fill={'outline'} onClick={() => reset()}>
                        {resetText}
                      </Button>
                      <Button type="submit" disabled={isSubmitDisabled}>
                        {submitSettings}
                      </Button>
                    </HorizontalGroup>
                    <ConfirmModal
                      isOpen={showConfirmModal}
                      title={submitFftZoomSettingsTxt}
                      body={`${
                        !isCurrentFFTEqualToCurrentSettings ? settingsNotReceived : ''
                      } ${sendConfirmationMessage}`}
                      confirmText={submitSettings}
                      onConfirm={() => submitSettingsForm()}
                      onDismiss={() => setShowConfirmModal(false)}
                    />
                  </div>
                </>
              );
            }}
          </Form>
        </div>
      )}
    </>
  );
};
