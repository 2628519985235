import { SelectableValue } from '@grafana/data';
import { DicoProps, TranslationProps } from 'types/dictionary';

/**
 * Format array to be selectable options in select UI element
 * @param array
 * @returns SelectableValue[]
 */

const makeOptionsSelectable = (array: any): SelectableValue[] => {
  return array.map((value: string | number) => ({
    label: typeof value === 'number' ? value.toString() : value,
    value: value,
  }));
};

/**
 * Build array of numbers for selectable options
 * @returns SelectableValue[]
 */

export const getNumberOptions = (start: number, length: number) => {
  const numbers = [] as number[];
  let n = start;
  while (n < length) {
    numbers.push(n);
    n++;
  }
  return makeOptionsSelectable(numbers);
};

/**
 * Build array of numbers for selectable options for seconds
 * @returns SelectableValue[]
 */

export const getSecondsOptions = () => {
  const numbers = [] as number[];
  let n = 0;
  while (n < 60) {
    numbers.push(n);
    n += 10;
  }
  return makeOptionsSelectable(numbers);
};

/**
 * Synchronization options
 * @returns SelectableValue[]
 * Index of array used to macth the current value
 */

export const getWakeOnEventModes = (dico: TranslationProps): SelectableValue[] => {
  const { descriptionFlagWoc, descriptionFlagWoa } = dico;
  return [
    {
      label: 'Inactive',
      value: 'Inactive',
      description: '',
    },
    {
      label: 'Wake-On-Motion',
      value: 'Wake-On-Motion',
      description: '',
    },
    {
      label: 'RESERVED',
      value: 'RESERVED',
      description: '',
    },
    {
      label: 'Wake-On-Scheduler',
      value: 'Wake-On-Scheduler',
      description: '',
    },
    {
      label: 'Wake-On-Analog',
      value: 'Wake-On-Analog',
      description: descriptionFlagWoa,
    },
    {
      label: 'Wake-On-Contact',
      value: 'Wake-On-Contact',
      description: descriptionFlagWoc,
    },
  ];
};

/**
 * Wake-On-Motion option
 * @returns SelectableValue[]
 */

export const getFlagWomMode = (dico: TranslationProps): SelectableValue[] => {
  return [
    {
      label: 'Red LED',
      value: 'Red LED',
      description: dico.descriptionFlagWom,
    },
    {
      label: 'No LED',
      value: 'No LED',
      description: '',
    },
  ];
};

/**
 * Wake-On-Scheduler option
 * @returns SelectableValue[]
 */

export const getFlagWosMode = () => {
  return [
    { label: 'Standard', value: 'Standard' },
    { label: 'Reserved', value: 'Reserved' },
  ];
};

/**
 * Wake-On-Analog option
 * @returns SelectableValue[]
 */

export const getFlagWoaMode = () => {
  return [
    {
      label: 'OUTSIDE Region',
      value: 'OUTSIDE Region',
    },
    {
      label: 'INSIDE Region',
      value: 'INSIDE Region',
    },
  ];
};

/**
 * Wake-On-Contact option
 * @returns SelectableValue[]
 */

export const getFlagWocMode = () => {
  return [
    { label: 'Edge ↓', value: 'Edge ↓' },
    { label: 'Edge ↑', value: 'Edge ↑' },
  ];
};

/**
 * FFT Zoom periodicity modes
 * @returns SelectableValue[]
 */

export const getPeriodicityModes = (): SelectableValue[] => {
  return [
    { label: 'Inactive', value: 0 },
    { label: 'Periodic', value: 1 },
    { label: 'Burst', value: 2 },
    { label: 'Permanent', value: 11 },
  ];
};

/**
 * Sensor types
 * @returns SelectableValue[]
 */

export const getSensorTypes = (dico: DicoProps): SelectableValue[] => {
  const { accelerometer, microphone } = dico.dico || {};

  return [
    { label: accelerometer, value: '3' },
    { label: microphone, value: '12' },
  ];
};

/**
 * Sensor orientation options
 * @returns SelectableValue[]
 */

export const getOrientations = (): SelectableValue[] => {
  return [
    { label: 'X', value: 1 },
    { label: 'Y', value: 2 },
    { label: 'Z', value: 4 },
    { label: 'XYZ', value: 0 },
  ];
};

export const getSignatureSensorOrientations = (): SelectableValue[] => {
  return [
    { label: 'X', value: '010000' },
    { label: 'Y', value: '020000' },
    { label: 'Z', value: '040000' },
    { label: 'XYZ', value: '000000' },
  ];
};

/**
 * Signature settings / Spectrum types (custom spectrogram)
 * @returns SelectableValue[]
 */

export const getSpectrumTypesBasis = (dico: DicoProps): SelectableValue[] => {
  const { spectrumRmsDesc, spectrumPeakDesc } = dico.dico || {};

  return [
    {
      label: 'RMS',
      value: '1',
      description: spectrumRmsDesc,
    },
    {
      label: 'Peak',
      value: '2',
      description: spectrumPeakDesc,
    },
  ];
};
export const getSpectrumTypes = (dico: DicoProps): SelectableValue[] => {
  const { spectrumVelocityPeakDesc, spectrumVelocityRmsDesc } = dico.dico || {};
  const spectrumBasis = getSpectrumTypesBasis(dico);

  return [
    ...spectrumBasis,
    {
      label: 'Velocity RMS',
      value: '3',
      description: spectrumVelocityRmsDesc,
    },
    {
      label: 'Velocity Peak',
      value: '4',
      description: spectrumVelocityPeakDesc,
    },
  ];
};

export const getFftSpectrumTypes = (dico: DicoProps): SelectableValue[] => {
  const { spectrumEnvelopeRmsDesc, spectrumEnvelopePeakDesc } = dico.dico || {};
  const generalTypes = getSpectrumTypes(dico);

  return [
    ...generalTypes,
    {
      label: 'Envelope RMS',
      value: '5',
      description: spectrumEnvelopeRmsDesc,
    },
    {
      label: 'Envelope Peak',
      value: '6',
      description: spectrumEnvelopePeakDesc,
    },
  ];
};

export const getCompressionOptions = (): SelectableValue[] => {
  return [
    { label: '50 bands (8 bits)', value: 0 },
    { label: '50 bands (16 bits)', value: 1 },
    { label: '100 bands (8 bits)', value: 2 },
    { label: '100 bands (16 bits)', value: 3 },
    { label: '200 bands (8 bits)', value: 4 },
  ];
};

export const getWoeFlags = (dico: TranslationProps) => {
  const WOM_FLAG = getFlagWomMode(dico);
  const WOS_FLAG = getFlagWosMode();
  const WOA_FLAG = getFlagWoaMode();
  const WOC_FLAG = getFlagWocMode();
  return {
    wom: WOM_FLAG,
    wos: WOS_FLAG,
    woa: WOA_FLAG,
    woc: WOC_FLAG,
  };
};
