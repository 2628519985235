import * as React from 'react';

import { InlineField, Input, InputControl, Select } from '@grafana/ui';

import { SubSectionProps, WoeMode } from 'types/form';
import { getWakeOnEventModes, getWoeFlags } from 'utils/formOptions';
import { UseFormClearErrors, UseFormSetValue } from 'react-hook-form';
import { validateValue } from 'utils/formEvents';
import { AppContext } from 'components/SimplePanel';

export const SubSectionSyncModes: React.FunctionComponent<SubSectionProps> = ({
  watch,
  setValue,
  getValues,
  setError,
  clearErrors,
  errors,
  control,
  register,
}) => {
  const { dico } = React.useContext(AppContext);
  const {
    mode,
    syncModePlaceholder,
    vibWakeupThr,
    delayedMeasurement,
    option,
    endOfCycleDelay,
    standard,
    regionUpperLimit,
    regionLowerLimit,
    fieldRequired,
  } = dico.dico || {};

  // WOE: Wake-On-Events, WOM: Wake-On-Motion, WOS: Wake-On-Scheduler, WOA: Wake-On-Analog, WOC: Wake-On-Controller
  const SYNC_MODES = getWakeOnEventModes(dico.dico);
  const FLAGS = getWoeFlags(dico.dico);

  const handleWoeModeChange = (e: WoeMode, setValue: UseFormSetValue<any>, clearErrors: UseFormClearErrors<any>) => {
    switch (e) {
      case WoeMode.scheduler:
        setValue('woe_flag', FLAGS.wos[0].value);
        clearErrors(['woe_threshold', 'woe_pretrig_threshold', 'woe_posttrig_threshold', 'woe_param']);
        setValue('woe_threshold', 0);
        setValue('woe_posttrig_threshold', 0);
        setValue('woe_pretrig_threshold', 0);
        setValue('woe_param', 0);
        break;
      case WoeMode.motion:
        setValue('woe_flag', FLAGS.wom[0].value);
        setValue('woe_threshold', 100);
        setValue('woe_pretrig_threshold', 0);
        setValue('woe_posttrig_threshold', 0);
        clearErrors(['woe_threshold', 'woe_pretrig_threshold', 'woe_posttrig_threshold']);
        // Reset other
        setValue('woe_param', 0);
        break;
      case WoeMode.analog:
        setValue('woe_flag', FLAGS.woa[0].value);
        setValue('woe_threshold', 10);
        setValue('woe_param', 20);
        setValue('woe_pretrig_threshold', 0);
        clearErrors(['woe_threshold', 'woe_param', 'woe_pretrig_threshold']);
        // Reset other
        setValue('woe_posttrig_threshold', 0);
        break;
      case WoeMode.contact:
        setValue('woe_flag', FLAGS.woc[0].value);
        setValue('woe_pretrig_threshold', 0);
        clearErrors('woe_pretrig_threshold');
        // Reset other
        setValue('woe_threshold', 0);
        setValue('woe_posttrig_threshold', 0);
        setValue('woe_param', 0);
        break;
      default:
        break;
    }
    setValue('woe_mode', e);
    clearErrors(['woe_mode']);
  };

  return (
    <>
      <InlineField
        label={mode}
        labelWidth={24}
        htmlFor="syncModes"
        required
        invalid={!!errors?.woe_mode?.message}
        error={errors?.woe_mode?.message}
        {...register('woe_mode', {
          required: fieldRequired,
        })}>
        <InputControl
          render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
            <Select
              {...field}
              invalid={invalid}
              options={SYNC_MODES.filter((option) => option.value !== 'RESERVED')}
              onChange={(e) => e.value && handleWoeModeChange(e.value, setValue, clearErrors)}
              inputId="syncModes"
              width={40}
              maxMenuHeight={200}
              placeholder={syncModePlaceholder}
            />
          )}
          control={control}
          name="woe_mode"
        />
      </InlineField>
      {/* Conditonal rendering of elements for Wake-On-Motion mode */}
      {watch('woe_mode') === SYNC_MODES[1].value && (
        <>
          <InlineField label={option} labelWidth={24} htmlFor="womOption">
            <InputControl
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  options={FLAGS.wom}
                  onChange={(e) => e.value && setValue('woe_flag', e.value)}
                  inputId="womOption"
                  width={40}
                />
              )}
              control={control}
              name="woe_flag"
            />
          </InlineField>
          <InlineField
            label={vibWakeupThr}
            labelWidth={24}
            htmlFor="womVibThresh"
            invalid={!!errors.woe_threshold}
            error={errors.woe_threshold?.message}
            required>
            <InputControl
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  id="womVibThresh"
                  type={'number'}
                  width={16}
                  suffix={'mg'}
                  invalid={invalid}
                  required
                  onChange={(e) =>
                    validateValue(
                      'woe_threshold',
                      e.currentTarget.value,
                      getValues,
                      setValue,
                      setError,
                      clearErrors,
                      dico.dico
                    )
                  }
                />
              )}
              control={control}
              name="woe_threshold"
            />
          </InlineField>
          <InlineField
            label={delayedMeasurement}
            labelWidth={24}
            htmlFor="womDelayMeas"
            invalid={!!errors.woe_pretrig_threshold}
            error={errors.woe_pretrig_threshold?.message}
            required>
            <InputControl
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  id="womDelayMeas"
                  type={'number'}
                  width={16}
                  suffix={'s'}
                  required
                  onChange={(e) =>
                    validateValue(
                      'woe_pretrig_threshold',
                      e.currentTarget.value,
                      getValues,
                      setValue,
                      setError,
                      clearErrors,
                      dico.dico
                    )
                  }
                />
              )}
              control={control}
              name="woe_pretrig_threshold"
            />
          </InlineField>
          <InlineField
            label={endOfCycleDelay}
            labelWidth={24}
            htmlFor="womEndCycle"
            invalid={!!errors.woe_posttrig_threshold}
            error={errors.woe_posttrig_threshold?.message}
            required>
            <InputControl
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  id="womEndCycle"
                  type={'number'}
                  width={16}
                  suffix={'s'}
                  required
                  onChange={(e) =>
                    validateValue(
                      'woe_posttrig_threshold',
                      e.currentTarget.value,
                      getValues,
                      setValue,
                      setError,
                      clearErrors,
                      dico.dico
                    )
                  }
                />
              )}
              control={control}
              name="woe_posttrig_threshold"
            />
          </InlineField>
        </>
      )}
      {/* Conditonal rendering of elements for Wake-On-Scheduler mode */}
      {watch('woe_mode') === SYNC_MODES[3].value && (
        <>
          <InlineField label={option} labelWidth={24} htmlFor="wosOption">
            <InputControl
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  options={FLAGS.wos.filter((option) => option.label === standard)}
                  onChange={(e) => e.value && setValue('woe_flag', e.value)}
                  inputId="wosOption"
                  width={40}
                />
              )}
              control={control}
              name="woe_flag"
            />
          </InlineField>
        </>
      )}
      {/* Conditonal rendering of elements for Wake-On-Analog mode */}
      {watch('woe_mode') === SYNC_MODES[4].value && (
        <>
          <InlineField label={option} labelWidth={24} htmlFor="woaOption">
            <InputControl
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  options={FLAGS.woa}
                  onChange={(e) => e.value && setValue('woe_flag', e.value)}
                  inputId="woaOption"
                  width={40}
                />
              )}
              control={control}
              name="woe_flag"
            />
          </InlineField>
          <InlineField
            label={regionUpperLimit}
            labelWidth={24}
            htmlFor="woaUpperLimit"
            invalid={!!errors.woe_param}
            error={errors.woe_param?.message}>
            <InputControl
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  id="woaUpperLimit"
                  type={'number'}
                  width={16}
                  suffix={'mA'}
                  invalid={invalid}
                  min={0}
                  onChange={(e) =>
                    validateValue(
                      'woe_param',
                      e.currentTarget.value,
                      getValues,
                      setValue,
                      setError,
                      clearErrors,
                      dico.dico
                    )
                  }
                />
              )}
              control={control}
              name="woe_param"
            />
          </InlineField>
          <InlineField
            label={regionLowerLimit}
            labelWidth={24}
            htmlFor="woaLowerLimit"
            invalid={!!errors.woe_threshold}
            error={errors.woe_threshold?.message}>
            <InputControl
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  id="woaLowerLimit"
                  type={'number'}
                  width={16}
                  suffix={'mA'}
                  invalid={invalid}
                  min={0}
                  onChange={(e) =>
                    validateValue(
                      'woe_threshold',
                      e.currentTarget.value,
                      getValues,
                      setValue,
                      setError,
                      clearErrors,
                      dico.dico
                    )
                  }
                />
              )}
              control={control}
              name="woe_threshold"
            />
          </InlineField>
          <InlineField
            label={delayedMeasurement}
            labelWidth={24}
            htmlFor="woaDelayMeas"
            invalid={!!errors.woe_pretrig_threshold}
            error={errors.woe_pretrig_threshold?.message}
            required>
            <InputControl
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  id="woaDelayMeas"
                  type={'number'}
                  min={0}
                  width={16}
                  suffix={'s'}
                  invalid={invalid}
                  required
                  onChange={(e) =>
                    validateValue(
                      'woe_pretrig_threshold',
                      e.currentTarget.value,
                      getValues,
                      setValue,
                      setError,
                      clearErrors,
                      dico.dico
                    )
                  }
                />
              )}
              control={control}
              name="woe_pretrig_threshold"
            />
          </InlineField>
        </>
      )}
      {/* Conditonal rendering of elements for Wake-On-Contact mode */}
      {watch('woe_mode') === SYNC_MODES[5].value && (
        <>
          <InlineField label={option} labelWidth={24} htmlFor="wocOption">
            <InputControl
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  options={FLAGS.woc}
                  onChange={(e) => e.value && setValue('woe_flag', e.value)}
                  inputId="wocOption"
                  width={40}
                />
              )}
              control={control}
              name="woe_flag"
            />
          </InlineField>
          <InlineField
            label={delayedMeasurement}
            labelWidth={24}
            htmlFor="wocDelayMeas"
            invalid={!!errors.woe_pretrig_threshold}
            error={errors.woe_pretrig_threshold?.message}>
            <InputControl
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  id="wocDelayMeas"
                  type={'number'}
                  min={0}
                  width={16}
                  suffix={'s'}
                  invalid={invalid}
                  required
                  onChange={(e) =>
                    validateValue(
                      'woe_pretrig_threshold',
                      e.currentTarget.value,
                      getValues,
                      setValue,
                      setError,
                      clearErrors,
                      dico.dico
                    )
                  }
                />
              )}
              control={control}
              name="woe_pretrig_threshold"
            />
          </InlineField>
        </>
      )}
    </>
  );
};
