import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => {
  return {
    sectionContainer: css`
      display: flex;
      width: 100%;
      flex-direction: column;
    `,
    section: css`
      padding: 24px;
      border: 1px solid ${theme.colors.border.weak};
      border-radius: 4px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: 600px;
      width: 100%;
      margin-bottom: 12px;
      h5 {
        margin-top: 16px;
      }
    `,
    sectionHorizontal: css`
      padding: 24px;
      border: 1px solid ${theme.colors.border.weak};
      border-radius: 4px;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 600px;
      width: 100%;
      margin-bottom: 12px;
      h5 {
        margin-top: 16px;
      }
    `,
    submit: css`
      margin-top: 32px;
    `,
    commandsContainer: css`
      height: 100%;
    `,
    sectionTitle: css`
      flex: 2;
      display: flex;
      align-items: center;
    `,
  };
};
