import * as React from 'react';

import { useStyles2, useTheme2, Form, Button, HorizontalGroup, Alert, Select } from '@grafana/ui';

import { UseFormSetValue, UseFormClearErrors } from 'react-hook-form';
import { getStyles } from '../getSectionsStyles';
import { AppContext } from 'components/SimplePanel';

import { SubSectionCustomSpectrogram } from './SubSectionCustomSpectrogram';
import { SubSectionSyncModes } from './SubSectionSyncModes';
import { SubSectionRpmSettings } from './SubSectionRpmSettings';
import { SubSectionRadio } from './SubSectionRadio';
import { useFormSectionSignatureSettings } from 'hooks/useFormSignatureSettings';
import { useSettingsDefault } from 'hooks/useSettingsDefault';
import { SignatureFormDTO, SignatureParameter } from '../../../types/form';

/** (Private settings) */
export const SectionSignatureSettings: React.FunctionComponent = () => {
  const { dico, user, isVersionFound } = React.useContext(AppContext);
  const {
    titleSignature,
    subtitleSyncMode,
    subtitleRpm,
    submitSettings,
    resetText,
    selectVersionDefault,
    alertCardTitle,
    customSpectrogram,
  } = dico.dico || {};

  const theme = useTheme2();
  const styles = useStyles2(() => getStyles(theme));

  const { canUseCustomSpectrogram, confirmSettings, settings } = useFormSectionSignatureSettings();

  const { selectedVersion, setSelectedVersion, VERSION_OPTIONS, handleVersion, getDefaultSettings } =
    useSettingsDefault();

  const loadDefaultSettings = (
    selectedVersionRange: string,
    setValue: UseFormSetValue<SignatureFormDTO>,
    clearErrors: UseFormClearErrors<SignatureFormDTO>
  ) => {
    if (!selectedVersionRange) {
      return;
    }
    setSelectedVersion(selectedVersionRange);
    const assumedVersion = handleVersion(selectedVersionRange);
    const defaultSettings = getDefaultSettings(assumedVersion);
    clearErrors();
    for (const [key, formValue] of Object.entries(defaultSettings)) {
      setValue(key as SignatureParameter, formValue);
    }
  };

  return (
    <div className={styles.section}>
      <Form defaultValues={settings} onSubmit={(form) => confirmSettings(form)} validateOn={'onChange'} noValidate>
        {({ control, register, watch, setValue, getValues, errors, setError, clearErrors, reset }) => (
          <>
            <h4>{titleSignature?.toUpperCase()}</h4>
            {/* Synchronization modes  */}
            <h5>{subtitleSyncMode}</h5>
            <SubSectionSyncModes
              clearErrors={clearErrors}
              control={control}
              errors={errors}
              getValues={getValues}
              register={register}
              setError={setError}
              setValue={setValue}
              watch={watch}
              dico={dico.dico}
            />
            {/* RPM settings */}
            <h5>{subtitleRpm}</h5>
            <SubSectionRpmSettings
              clearErrors={clearErrors}
              control={control}
              errors={errors}
              getValues={getValues}
              register={register}
              setError={setError}
              setValue={setValue}
              watch={watch}
              dico={dico.dico}
            />
            {/* Custom spectrogram if version >= 4.45 */}
            {canUseCustomSpectrogram && (
              <>
                <h5>{customSpectrogram}</h5>
                <SubSectionCustomSpectrogram
                  clearErrors={clearErrors}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  register={register}
                  setError={setError}
                  setValue={setValue}
                  watch={watch}
                  dico={dico.dico}
                />
              </>
            )}
            {user.orgRole === 'Admin' && (
              <>
                <h5>LoRaWAN</h5>
                <SubSectionRadio
                  clearErrors={clearErrors}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  register={register}
                  setError={setError}
                  setValue={setValue}
                  watch={watch}
                  defaultValues={settings!}
                  dico={dico.dico}
                />
              </>
            )}
            {!isVersionFound && user.orgRole === 'Admin' && (
              <div style={{ marginTop: '16px' }}>
                <Alert title={alertCardTitle} severity="info">
                  <Select
                    value={selectedVersion}
                    options={VERSION_OPTIONS}
                    placeholder={selectVersionDefault}
                    onChange={(e) => e.value && loadDefaultSettings(e.value, setValue, clearErrors)}
                  />
                </Alert>
              </div>
            )}
            {/* Reset & Submit */}
            <div className={styles.submit}>
              <HorizontalGroup spacing={'sm'} justify={'flex-start'} align={'center'}>
                <Button type="reset" variant={'secondary'} fill={'outline'} onClick={() => reset()}>
                  {resetText}
                </Button>
                <Button type="submit" disabled={!!Object.keys(errors).length}>
                  {submitSettings}
                </Button>
              </HorizontalGroup>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
