import * as React from 'react';
import { getBackendSrv, getTemplateSrv } from '@grafana/runtime';
import { TranslationProps } from 'types/dictionary';

/**
 * Fetch dictionaries in the standard-settings direction
 * {lang}.json
 */

const DICTIONARY_URL = '/public/asystom/asystom-dictionaries/standard-settings/';

export const useDictionary = () => {
  const [dico, setDico] = React.useState({} as TranslationProps);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const abortController = new AbortController();
    let langVar = getTemplateSrv()?.replace('$lang_selection');
    if (!langVar) {
      langVar = 'EN';
    }

    const fetchData = async () => {
      try {
        const response = await getBackendSrv().get(`${DICTIONARY_URL}${langVar}.json`, {
          signal: abortController.signal,
        });
        setDico(response);
      } catch (error: any) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  return { dico, error, loading };
};
