import { FftFormDTO, FFTProps, Orientation, SensorType, SignatureFormDTO, SignatureParameter } from 'types/form';
import { UseFormClearErrors, UseFormGetValues, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import {
  ACCELERO_MAX_FREQ,
  MAX_CUTOFF_VALUE,
  MICRO_MAX_FREQ,
  MIN_INTERVAL_ACCEL,
  MIN_INTERVAL_MICRO,
} from 'utils/constants';
import { private_settings_model, radio_model, woe_modes_model } from './settings_models';
import { TranslationProps } from 'types/dictionary';

const WOA_PARAMETER_LIMIT = 30;

/**
 * Validation of custom spectrogram maximum frequency
 * Signature settings section
 * @param inputName
 * @param e Note that input value is always a string (even if type is specified number)
 * @param getValues
 * @param setValue
 * @param setError
 * @param clearErrors
 * @returns
 */

export const validateValue = (
  inputName: SignatureParameter,
  e: string,
  getValues: UseFormGetValues<SignatureFormDTO>,
  setValue: UseFormSetValue<SignatureFormDTO>,
  setError: UseFormSetError<SignatureFormDTO>,
  clearErrors: UseFormClearErrors<SignatureFormDTO>,
  dico: TranslationProps
) => {
  const inputValue = parseInt(e, 10);

  clearErrors(inputName);
  setValue(inputName, inputValue.toString());

  if (!e) {
    setError(inputName, {
      type: 'custom',
      message: dico.fieldRequired,
    });
    return;
  }

  // ACCELEROMETER MAX
  if (inputName === 'accelero_freq_max') {
    // Check upper limit
    if (inputValue > ACCELERO_MAX_FREQ) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.maxFreqInferior} ${ACCELERO_MAX_FREQ}Hz`,
      });
      return;
    }

    // Check max <= min
    if (inputValue <= getValues('accelero_freq_min')) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.maxFreqSupToMin}`,
      });
      return;
    }

    // Check min limit
    if (inputValue < MIN_INTERVAL_ACCEL) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.maxFreqSuperior} ${MIN_INTERVAL_ACCEL}Hz`,
      });
      return;
    }

    if (
      getValues('accelero_freq_min') < inputValue &&
      getValues('accelero_freq_min') <= ACCELERO_MAX_FREQ - MIN_INTERVAL_ACCEL &&
      inputValue - getValues('accelero_freq_min') >= MIN_INTERVAL_ACCEL
    ) {
      clearErrors('accelero_freq_min');
    }
  }

  // ACCELEROMETER  MIN
  if (inputName === 'accelero_freq_min') {
    // Check min does not exceed upper limit
    if (inputValue > ACCELERO_MAX_FREQ - MIN_INTERVAL_ACCEL) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.minFreqInferior} ${ACCELERO_MAX_FREQ - MIN_INTERVAL_ACCEL}Hz`,
      });
      return;
    }

    // Check min !<= max
    if (inputValue >= getValues('accelero_freq_max')) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.minFreqInfToMax}`,
      });
      return;
    }

    if (
      getValues('accelero_freq_max') > inputValue &&
      getValues('accelero_freq_max') <= ACCELERO_MAX_FREQ &&
      getValues('accelero_freq_max') - inputValue >= MIN_INTERVAL_ACCEL
    ) {
      clearErrors('accelero_freq_max');
    }
  }

  // MICROPHONE MAX
  if (inputName === 'micro_freq_max') {
    // Check upper limit
    if (inputValue > MICRO_MAX_FREQ) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.maxFreqSuperior} ${MICRO_MAX_FREQ}Hz`,
      });
      return;
    }

    // Check max <= min
    if (inputValue <= getValues('micro_freq_min')) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.maxFreqSupToMin}`,
      });
      return;
    }

    // Check min limit
    if (inputValue < MIN_INTERVAL_MICRO) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.maxFreqSuperior} ${MIN_INTERVAL_MICRO}Hz`,
      });
      return;
    }

    if (
      getValues('micro_freq_min') < inputValue &&
      getValues('micro_freq_min') <= MICRO_MAX_FREQ - MIN_INTERVAL_MICRO &&
      inputValue - getValues('micro_freq_min') >= MIN_INTERVAL_MICRO
    ) {
      clearErrors('micro_freq_min');
    }
  }

  // MICROPHONE MIN
  if (inputName === 'micro_freq_min') {
    // Check max does not exceed upper limit
    if (inputValue > MICRO_MAX_FREQ - MIN_INTERVAL_MICRO) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.minFreqInferior} ${MICRO_MAX_FREQ - MIN_INTERVAL_MICRO}Hz`,
      });
      return;
    }

    // Check max <= min
    if (inputValue >= getValues('micro_freq_max')) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.minFreqInfToMax}`,
      });
      return;
    }

    if (
      getValues('micro_freq_max') > inputValue &&
      getValues('micro_freq_max') <= MICRO_MAX_FREQ &&
      getValues('micro_freq_max') - inputValue >= MIN_INTERVAL_MICRO
    ) {
      clearErrors('micro_freq_max');
    }
  }

  // RPM MAX
  if (inputName === 'rpm_max') {
    // Check max !<= min
    if (inputValue <= getValues('rpm_min')) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.maxRpmSuperior}`,
      });
      return;
    }
    if (getValues('rpm_min') < inputValue) {
      clearErrors('rpm_min');
    }
  }

  // RPM MIN
  if (inputName === 'rpm_min') {
    // Check max <= min
    if (inputValue >= getValues('rpm_max')) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.minRpmInferior}`,
      });
      return;
    }

    if (getValues('rpm_max') > inputValue) {
      clearErrors('rpm_max');
    }
  }

  // REGION MAX (Wake-on-Analog) MAX
  if (inputName === 'woe_param' && getValues('woe_mode') === 'Wake-On-Analog') {
    if (inputValue > WOA_PARAMETER_LIMIT) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.regionUpperLimitInferior} ${WOA_PARAMETER_LIMIT}mA`,
      });
      return;
    }

    if (getValues('woe_threshold') >= inputValue) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.regionUpperSupToLower}`,
      });
      return;
    }

    if (getValues('woe_threshold') <= WOA_PARAMETER_LIMIT && getValues('woe_threshold') < inputValue) {
      clearErrors('woe_threshold');
    }
  }

  // REGION MIN (Wake-on-Analog) MAX
  if (inputName === 'woe_threshold' && getValues('woe_mode') === 'Wake-On-Analog') {
    if (inputValue > WOA_PARAMETER_LIMIT) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.regionLowerLimitInferior} ${WOA_PARAMETER_LIMIT}mA`,
      });
      return;
    }

    if (inputValue >= getValues('woe_param')) {
      setError(inputName, {
        type: 'custom',
        message: `${dico.regionLowerInfToSup}`,
      });
      return;
    }

    if (getValues('woe_param') <= WOA_PARAMETER_LIMIT && getValues('woe_param') > inputValue) {
      clearErrors('woe_param');
    }
  }

  const allMax = {
    ...private_settings_model,
    ...woe_modes_model,
    ...radio_model,
  };
  if (inputName in allMax) {
    if (inputValue > allMax[inputName as SignatureParameter]?.max!) {
      setError(inputName, {
        type: 'custom',
        message: `${inputName} ${dico.mustBe} < ${allMax[inputName as SignatureParameter].max!}`,
      });
      return;
    }
  }
};

/**
 * FFT ZOOM SETTINGS
 * Validation of maximum frequency (FFT spectrum)
 * @param name
 * @param e Note that input value is always a string (even if type is specified number)
 * @param setValue
 * @param setError
 * @param clearErrors
 * @param getValues
 * @returns
 */

export const validateFft = (
  name: FFTProps,
  e: string | number,
  setValue: UseFormSetValue<FftFormDTO>,
  setError: UseFormSetError<FftFormDTO>,
  clearErrors: UseFormClearErrors<FftFormDTO>,
  getValues: UseFormGetValues<FftFormDTO>,
  dico: TranslationProps
) => {
  const inputValue = typeof e === 'number' ? e : parseInt(e, 10);

  clearErrors(name);
  setValue(name, e);
  const sensor = getValues('fft_sensor_type');

  if (!e) {
    setError(name, {
      type: 'custom',
      message: dico.fieldRequired,
    });
    return;
  }
  const minValue = parseInt(getValues('fft_freq_min'), 10);
  const maxValue = parseInt(getValues('fft_freq_max'), 10);

  // Accelerometer max
  if (sensor === SensorType.accelerometer && name === 'fft_freq_max') {
    // Check max > min
    if (inputValue <= minValue) {
      setError(name, {
        type: 'custom',
        message: dico.maxFreqSupToMin,
      });
      return;
    }

    // Check max not exceed max freq accelerometer
    if (inputValue > ACCELERO_MAX_FREQ) {
      setError(name, {
        type: 'custom',
        message: `${dico.maxFreqInferior} ${ACCELERO_MAX_FREQ}Hz`,
      });
      return;
    }

    // Check max - min >= MIN_INTERVAL_ACCEL
    if (inputValue - minValue < MIN_INTERVAL_ACCEL) {
      setError(name, {
        type: 'custom',
        message: `${dico.maxFreqSuperior} ${minValue + MIN_INTERVAL_ACCEL}Hz`,
      });
      return;
    }

    // Check min max limit
    if (inputValue < MIN_INTERVAL_ACCEL) {
      setError(name, {
        type: 'custom',
        message: `${dico.maxFreqSuperior} ${MIN_INTERVAL_ACCEL}Hz`,
      });
      return;
    }

    if (
      minValue < inputValue &&
      minValue <= ACCELERO_MAX_FREQ - MIN_INTERVAL_ACCEL &&
      inputValue - minValue >= MIN_INTERVAL_ACCEL
    ) {
      clearErrors('fft_freq_min');
    }
  }

  if (sensor === SensorType.accelerometer && name === 'fft_freq_min') {
    // Check min > max
    if (inputValue >= maxValue) {
      setError('fft_freq_min', {
        type: 'custom',
        message: `${dico.minFreqInfToMax}`,
      });
      return;
    }

    // check interval [min - max]
    if (maxValue - inputValue < MIN_INTERVAL_ACCEL) {
      setError('fft_freq_min', {
        type: 'custom',
        message: `${dico.minFreqSupToZero} ${maxValue - MIN_INTERVAL_ACCEL}Hz`,
      });
      return;
    }

    // Check min not exceed min freq accelerometer (max - interval)
    if (inputValue > ACCELERO_MAX_FREQ - MIN_INTERVAL_ACCEL) {
      setError(name, {
        type: 'custom',
        message: `${dico.minFreqInferior} ${ACCELERO_MAX_FREQ - MIN_INTERVAL_ACCEL}Hz`,
      });
      return;
    }

    if (maxValue > inputValue && maxValue <= ACCELERO_MAX_FREQ && maxValue - inputValue >= MIN_INTERVAL_ACCEL) {
      clearErrors('fft_freq_max');
    }
  }

  /** MICROPHONE MAX */
  if (sensor === SensorType.microphone && name === 'fft_freq_max') {
    // Check max > min
    if (inputValue <= minValue) {
      setError(name, {
        type: 'custom',
        message: `${dico.maxFreqSupToMin}`,
      });
      return;
    }

    // Change max value if exceed max freq MICROPHONE
    if (inputValue > MICRO_MAX_FREQ) {
      setError(name, {
        type: 'custom',
        message: `${dico.maxFreqInferior} ${MICRO_MAX_FREQ}Hz`,
      });
      return;
    }

    // Check min max limit
    if (inputValue < MIN_INTERVAL_MICRO) {
      setError(name, {
        type: 'custom',
        message: `${dico.maxFreqSuperior} ${MIN_INTERVAL_MICRO}Hz`,
      });
      return;
    }

    // Check max - min >= MIN_INTERVAL_MICRO
    if (inputValue - minValue < MIN_INTERVAL_MICRO) {
      setError(name, {
        type: 'custom',
        message: `${dico.maxFreqSuperior} ${minValue + MIN_INTERVAL_MICRO}Hz`,
      });
      return;
    }

    if (
      minValue < inputValue &&
      minValue <= MICRO_MAX_FREQ - MIN_INTERVAL_MICRO &&
      inputValue - minValue >= MIN_INTERVAL_MICRO
    ) {
      clearErrors('fft_freq_min');
    }
  }

  /** MICROPHONE MIN */
  if (sensor === SensorType.microphone && name === 'fft_freq_min') {
    // Check min > max
    if (inputValue >= maxValue) {
      setError('fft_freq_min', {
        type: 'custom',
        message: `${dico.minFreqInfToMax}`,
      });
      return;
    }

    // Check [min-max] interval
    if (maxValue - inputValue < MIN_INTERVAL_MICRO) {
      setError('fft_freq_min', {
        type: 'custom',
        message: `${dico.minFreqSupToZero} ${maxValue - MIN_INTERVAL_MICRO}Hz`,
      });
      return;
    }

    // Check min not exceed min freq accelerometer (max - interval)
    if (inputValue > MICRO_MAX_FREQ - MIN_INTERVAL_MICRO) {
      setError(name, {
        type: 'custom',
        message: `${dico.minFreqInferior} ${MICRO_MAX_FREQ - MIN_INTERVAL_MICRO}Hz`,
      });
      return;
    }

    if (maxValue > inputValue && maxValue <= MICRO_MAX_FREQ && maxValue - inputValue >= MIN_INTERVAL_MICRO) {
      clearErrors('fft_freq_max');
    }
  }

  if (name === 'fft_cutoff') {
    if (inputValue > MAX_CUTOFF_VALUE) {
      setError('fft_cutoff', {
        type: 'custom',
        message: `${dico.cutOffValueLimits}`,
      });
      return;
    }
  }

  if (name === 'steps') {
    if (getValues('activation') === 2 && inputValue > 200) {
      // burst
      setError('steps', {
        type: 'custom',
        message: `Maximum 200`,
      });
      return;
    }
  }
};

/**
 * SIGNATURE SETTINGS SECTION
 * Default values changed depending on sensor type
 * On sensor type change, set by default freq min and max
 * @param inputValue SensorType
 * @param setValue
 * @returns
 */

export const handleSensorTypeChange = (
  inputValue: SensorType,
  setValue: UseFormSetValue<SignatureFormDTO>,
  clearErrors: UseFormClearErrors<SignatureFormDTO>
) => {
  clearErrors(['micro_freq_min', 'micro_freq_max', 'accelero_freq_min', 'accelero_freq_max']);
  setValue('sensor_type', inputValue as SensorType);

  if (inputValue === SensorType.microphone) {
    setValue('micro_freq_min', 0);
    setValue('micro_freq_max', MICRO_MAX_FREQ);
    setValue('sensor_orientation', Orientation.XYZ);
    setValue('custom_spectrum_type', '1'); // set by default RMS
    return;
  }

  setValue('accelero_freq_min', 0);
  setValue('accelero_freq_max', ACCELERO_MAX_FREQ);
  setValue('sensor_orientation', Orientation.X);
};

export const handleModeChange = (
  inputValue: number,
  setValue: UseFormSetValue<FftFormDTO>,
  clearErrors: UseFormClearErrors<FftFormDTO>
) => {
  clearErrors('steps');
  setValue('activation', inputValue);
  switch (inputValue) {
    case 2: // burst
      setValue('steps', 1);
      break;
    case 1: // periodic
      setValue('fft_spectr_type', '1');
      setValue('steps', 2);
      break;
    case 11: // permanent
      setValue('fft_spectr_type', '1');
      setValue('steps', 1);
      break;
    default:
      break;
  }
};

export const handleSpectrumTypeChange = (
  inputValue: string,
  setValue: UseFormSetValue<FftFormDTO>,
  clearErrors: UseFormClearErrors<FftFormDTO>
) => {
  clearErrors('fft_cutoff');
  setValue('fft_spectr_type', inputValue);
  if (inputValue === '5' || inputValue === '6') {
    setValue('fft_cutoff', '200');
  } else {
    setValue('fft_cutoff', '0');
  }
};

export const handleFftSensorTypeChange = (
  inputValue: SensorType,
  setValue: UseFormSetValue<FftFormDTO>,
  clearErrors: UseFormClearErrors<FftFormDTO>
) => {
  clearErrors();
  setValue('fft_sensor_type', inputValue as SensorType);
  setValue('fft_spectr_type', '1');
  setValue('fft_freq_min', '0');

  if (inputValue === SensorType.microphone) {
    setValue('fft_freq_max', MICRO_MAX_FREQ.toString());
  }

  if (inputValue === SensorType.accelerometer) {
    setValue('fft_freq_max', ACCELERO_MAX_FREQ.toString());
  }
};
