import React from 'react';
import {
  Alert,
  Button,
  Form,
  HorizontalGroup,
  InlineField,
  InlineFieldRow,
  InlineLabel,
  InlineSwitch,
  InputControl,
  Select,
  useStyles2,
  useTheme2,
  ConfirmModal,
} from '@grafana/ui';

import { getStyles } from '../getSectionsStyles';
import { AppContext } from 'components/SimplePanel';
import { MsgMapOpcodes } from 'utils/settings_models';
import { SubSectionActivation } from './SubSectionActivation';
import { useFormPublicSettings } from 'hooks/useFormPublicSettings';

/**
 * Scheduling and activation section
 * @returns ambient period field
 * @returns prediction period field
 * @returns sync field
 */

interface SectionPublicSettingsProps {
  setIsMainModalOpen: (value: boolean) => void;
}

type RequestSettingsType = 'reboot' | 'send' | 'request';

export const SectionPublicSettings: React.FunctionComponent<SectionPublicSettingsProps> = ({ setIsMainModalOpen }) => {
  const { dico, isVersionFound, user } = React.useContext(AppContext);

  const {
    HOURS,
    MINUTES,
    SECONDS,
    defaultSync,
    getSettingsValue,
    handleConfirmModal,
    handlePeriodChange,
    handleSwitch,
    isSync,
    setIsSync,
    setSettingsForm,
    setShowConfirmModalReboot,
    setShowConfirmModalSend,
    settings,
    showConfirmModalReboot,
    showConfirmModalSend,
    specialCommandRequest,
  } = useFormPublicSettings(setIsMainModalOpen);

  // Translations
  const {
    titleScheduling,
    titleSpecialCommands,
    subtitleScheduling,
    fieldAmbientPeriod,
    fieldPredictionPeriod,
    submitSettings,
    buttonRequestReboot,
    resetText,
    activation,
    introspection,
    reboot,
    confirmReboot,
    sendSettingsModalTitle1,
    sendConfirmationMessage,
    sendRebootButton,
  } = dico?.dico || {};

  const theme = useTheme2();
  const styles = useStyles2(() => getStyles(theme));

  return (
    <div className={styles.sectionContainer}>
      {/* Special commands */}
      {isVersionFound && (
        <>
          <div className={styles.sectionHorizontal}>
            <div className={styles.sectionTitle}>
              <h4>{titleSpecialCommands?.toUpperCase()}</h4>
            </div>
            <div className={styles.commandsContainer}>
              <HorizontalGroup spacing={'sm'} justify={'space-between'} align={'center'}>
                {/* Request Reboot */}
                <Button variant={'destructive'} size={'md'} icon={'sync'} onClick={() => handleConfirmModal('reboot')}>
                  {buttonRequestReboot}
                </Button>
                <ConfirmModal
                  isOpen={showConfirmModalReboot}
                  title={reboot}
                  body={confirmReboot}
                  confirmText={sendRebootButton}
                  onConfirm={() => specialCommandRequest('reboot', MsgMapOpcodes.LORA_REBOOT_ONLY)}
                  onDismiss={() => setShowConfirmModalReboot(false)}
                />
              </HorizontalGroup>
            </div>
          </div>
          {/* Scheduling and activation */}
          <div className={styles.section}>
            <Form
              style={{
                width: '100%',
                maxWidth: '750px',
              }}
              onSubmit={(form) => setSettingsForm(form)}
              validateOn={'onChange'}
              defaultValues={{
                ...settings?.public,
                ...settings?.indicators,
              }}>
              {({ control, setValue, getValues, reset, errors, setError, clearErrors, register }) => (
                <>
                  <h4>{titleScheduling?.toUpperCase()}</h4>
                  {user.orgRole === 'Admin' && (
                    <>
                      <h5>{activation}</h5>
                      <SubSectionActivation register={register} defaultValues={settings?.indicators} />
                    </>
                  )}
                  <h5>{subtitleScheduling}</h5>
                  {/* Ambient period */}
                  <InlineFieldRow width={'100%'}>
                    <InlineField
                      label={fieldAmbientPeriod}
                      labelWidth={24}
                      htmlFor="ambientHours"
                      invalid={!!errors.ambient_hours}
                      error={errors.ambient_hours?.message}>
                      <InputControl
                        render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
                          <Select
                            {...field}
                            options={HOURS}
                            inputId="ambientHours"
                            invalid={invalid}
                            onChange={(e) =>
                              handlePeriodChange(
                                e.value,
                                setValue,
                                getValues,
                                setError,
                                clearErrors,
                                'ambient_hours',
                                'prediction_hours'
                              )
                            }
                            width={12}
                          />
                        )}
                        control={control}
                        name="ambient_hours"
                      />
                    </InlineField>
                    <InlineField shrink>
                      <InlineLabel>h</InlineLabel>
                    </InlineField>
                    <InlineField
                      labelWidth={24}
                      htmlFor="ambientMinutes"
                      invalid={!!errors.ambient_minutes}
                      error={errors.ambient_minutes?.message}>
                      <InputControl
                        render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
                          <Select
                            {...field}
                            options={MINUTES}
                            inputId="ambientMinutes"
                            onChange={(e) =>
                              handlePeriodChange(
                                e.value,
                                setValue,
                                getValues,
                                setError,
                                clearErrors,
                                'ambient_minutes',
                                'prediction_minutes'
                              )
                            }
                            width={12}
                            invalid={invalid}
                          />
                        )}
                        control={control}
                        name="ambient_minutes"
                      />
                    </InlineField>
                    <InlineField shrink>
                      <InlineLabel>min</InlineLabel>
                    </InlineField>
                    {user.orgRole === 'Admin' && (
                      <>
                        <InlineField
                          labelWidth={24}
                          htmlFor="ambientSeconds"
                          invalid={!!errors.ambient_seconds}
                          error={errors.ambient_seconds?.message}>
                          <InputControl
                            render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
                              <Select
                                {...field}
                                options={SECONDS}
                                inputId="ambientSeconds"
                                onChange={(e) =>
                                  handlePeriodChange(
                                    e.value,
                                    setValue,
                                    getValues,
                                    setError,
                                    clearErrors,
                                    'ambient_seconds',
                                    'prediction_seconds'
                                  )
                                }
                                width={12}
                                invalid={invalid}
                              />
                            )}
                            control={control}
                            name="ambient_seconds"
                          />
                        </InlineField>
                        <InlineField shrink>
                          <InlineLabel>s</InlineLabel>
                        </InlineField>
                      </>
                    )}
                  </InlineFieldRow>
                  {/* Prediction period */}
                  <InlineFieldRow width={'100%'}>
                    <InlineField
                      label={fieldPredictionPeriod}
                      labelWidth={24}
                      htmlFor="predictionHours"
                      invalid={!!errors.prediction_hours}
                      error={errors.prediction_hours?.message}>
                      <InputControl
                        render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
                          <Select
                            {...field}
                            options={HOURS}
                            inputId="predictionHours"
                            onChange={(e) =>
                              handlePeriodChange(
                                e.value,
                                setValue,
                                getValues,
                                setError,
                                clearErrors,
                                'prediction_hours',
                                'ambient_hours'
                              )
                            }
                            disabled={isSync}
                            invalid={invalid}
                            width={12}
                          />
                        )}
                        control={control}
                        name="prediction_hours"
                      />
                    </InlineField>
                    <InlineField>
                      <InlineLabel>h</InlineLabel>
                    </InlineField>
                    <InlineField
                      labelWidth={24}
                      htmlFor="predictionMinutes"
                      invalid={!!errors.prediction_minutes}
                      error={errors.prediction_minutes?.message}>
                      <InputControl
                        render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
                          <Select
                            {...field}
                            options={MINUTES}
                            inputId="predictionMinutes"
                            onChange={(e) =>
                              handlePeriodChange(
                                e.value,
                                setValue,
                                getValues,
                                setError,
                                clearErrors,
                                'prediction_minutes',
                                'ambient_minutes'
                              )
                            }
                            disabled={isSync}
                            invalid={invalid}
                            width={12}
                          />
                        )}
                        control={control}
                        name="prediction_minutes"
                      />
                    </InlineField>
                    <InlineField>
                      <InlineLabel>min</InlineLabel>
                    </InlineField>
                    {user.orgRole === 'Admin' && (
                      <>
                        <InlineField
                          labelWidth={24}
                          htmlFor="predictionSeconds"
                          invalid={!!errors.ambient_seconds}
                          error={errors.ambient_seconds?.message}>
                          <InputControl
                            render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
                              <Select
                                {...field}
                                options={SECONDS}
                                inputId="predictionSeconds"
                                onChange={(e) =>
                                  handlePeriodChange(
                                    e.value,
                                    setValue,
                                    getValues,
                                    setError,
                                    clearErrors,
                                    'prediction_seconds',
                                    'ambient_seconds'
                                  )
                                }
                                width={12}
                                invalid={invalid}
                                disabled={isSync}
                              />
                            )}
                            control={control}
                            name="prediction_seconds"
                          />
                        </InlineField>
                        <InlineField shrink>
                          <InlineLabel>s</InlineLabel>
                        </InlineField>
                      </>
                    )}
                  </InlineFieldRow>
                  {/* Sync */}
                  <InlineFieldRow>
                    <InlineField label={'Sync'} labelWidth={24} htmlFor="sync">
                      <InlineSwitch
                        value={isSync}
                        id={'sync'}
                        onChange={() => handleSwitch(setValue, getValues, setError)}
                      />
                    </InlineField>
                  </InlineFieldRow>
                  {user.orgRole === 'Admin' && (
                    <>
                      {/* Introspection period */}
                      <InlineFieldRow width={'100%'}>
                        <InlineField
                          label={introspection}
                          labelWidth={24}
                          htmlFor="introspectionHours"
                          invalid={!!errors.introspection_hours}
                          error={errors.introspection_hours?.message}>
                          <InputControl
                            render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
                              <Select
                                {...field}
                                options={HOURS}
                                inputId="introspectionHours"
                                invalid={invalid}
                                onChange={(e) => setValue('introspection_hours', e.value)}
                                width={12}
                              />
                            )}
                            control={control}
                            name="introspection_hours"
                          />
                        </InlineField>
                        <InlineField shrink>
                          <InlineLabel>h</InlineLabel>
                        </InlineField>
                        <InlineField
                          labelWidth={24}
                          htmlFor="introspectionMinutes"
                          invalid={!!errors.introspection_minutes}
                          error={errors.introspection_minutes?.message}>
                          <InputControl
                            render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
                              <Select
                                {...field}
                                options={MINUTES}
                                inputId="introspectionMinutes"
                                onChange={(e) => setValue('introspection_minutes', e.value)}
                                width={12}
                                invalid={invalid}
                              />
                            )}
                            control={control}
                            name="introspection_minutes"
                          />
                        </InlineField>
                        <InlineField shrink>
                          <InlineLabel>min</InlineLabel>
                        </InlineField>
                        <InlineField
                          labelWidth={24}
                          htmlFor="introspectionSeconds"
                          invalid={!!errors.introspection_seconds}
                          error={errors.introspection_seconds?.message}>
                          <InputControl
                            render={({ field: { ref, ...field }, fieldState: { invalid } }) => (
                              <Select
                                {...field}
                                options={SECONDS}
                                inputId="introspectionSeconds"
                                onChange={(e) => setValue('introspection_seconds', e.value)}
                                width={12}
                                invalid={invalid}
                              />
                            )}
                            control={control}
                            name="introspection_seconds"
                          />
                        </InlineField>
                        <InlineField shrink>
                          <InlineLabel>s</InlineLabel>
                        </InlineField>
                      </InlineFieldRow>
                    </>
                  )}
                  {/* Submit */}
                  <div className={styles.submit}>
                    <HorizontalGroup spacing={'sm'} justify={'flex-start'} align={'center'}>
                      <Button
                        type="reset"
                        variant={'secondary'}
                        fill={'outline'}
                        onClick={() => {
                          reset();
                          setIsSync(defaultSync);
                        }}>
                        {resetText}
                      </Button>
                      <Button
                        type="submit"
                        disabled={!!Object.keys(errors).length}
                        onClick={() => handleConfirmModal('send')}>
                        {submitSettings}
                      </Button>
                      <ConfirmModal
                        isOpen={showConfirmModalSend}
                        title={sendSettingsModalTitle1}
                        body={sendConfirmationMessage}
                        confirmText={submitSettings}
                        onConfirm={() => getSettingsValue()}
                        onDismiss={() => setShowConfirmModalSend(false)}
                      />
                    </HorizontalGroup>
                  </div>
                </>
              )}
            </Form>
          </div>
        </>
      )}
      {!isVersionFound && <CardActionRequestSettings specialCommandRequest={specialCommandRequest} />}
    </div>
  );
};

interface CardActionRequestSettingsProps {
  specialCommandRequest: (requestType: RequestSettingsType, opCode: number) => void;
}

const CardActionRequestSettings: React.FunctionComponent<CardActionRequestSettingsProps> = ({
  specialCommandRequest,
}) => {
  const { dico, beacon } = React.useContext(AppContext);
  const { noDevice, noSettingsFor, requestSettingsMessage, requestSettingsButton } = dico?.dico || {};

  return (
    <Alert
      title={noDevice}
      severity={'warning'}
      buttonContent={<span>{requestSettingsButton}</span>}
      onRemove={() => specialCommandRequest('request', MsgMapOpcodes.LORA_REBOOT_ONLY)}>
      <>
        <div>
          {noSettingsFor} {beacon}.
        </div>
        <div>{requestSettingsMessage}</div>
      </>
    </Alert>
  );
};
