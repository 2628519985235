import { CascaderOption } from '@grafana/ui';

export const getQueryTemplates = (): CascaderOption[] => {
  return [
    {
      label: 'Show Databases',
      value: 'SHOW DATABASES',
    },
    {
      label: '-- ',
      value: '---',
      disabled: true,
    },
    {
      label: 'Show Measurements',
      value: 'SHOW MEASUREMENTS',
    },
    {
      label: 'Show Field Keys',
      value: `SHOW FIELD KEYS FROM "measurement_name"`,
    },
    {
      label: 'Show Tag Keys',
      value: `SHOW TAG KEYS FROM "measurement_name"`,
    },
    {
      label: 'Show Tag Values',
      value: `SHOW TAG VALUES FROM "measurement_name" WITH KEY = "tag_key"`,
    },
    {
      label: '--',
      value: '--',
      disabled: true,
    },
    {
      label: 'Show SettingsQueue',
      value: 'SELECT * FROM SettingsQueue ORDER BY time desc LIMIT 11',
    },
    {
      label: 'Show SettingsPublicBackup',
      value: 'SELECT * FROM SettingsPublicBackup ORDER BY time desc LIMIT 11',
    },
    {
      label: 'Show SettingsPrivateBackup',
      value: 'SELECT * FROM SettingsPrivateBackup ORDER BY time desc LIMIT 11',
    },
    {
      label: 'Show SettingsSignatureBackup',
      value: 'SELECT * FROM SettingsSignatureBackup ORDER BY time desc LIMIT 11',
    },
    {
      label: 'Show count() Battery for current device',
      value: `SELECT count(value) FROM Batt_Lvl where device='$beacon_selection'`,
    },
    {
      label: 'Show SettingsPublicBackup for current device',
      value: `SELECT * FROM SettingsPublicBackup where device='$beacon_selection' ORDER BY time desc LIMIT 11`,
    },
    {
      label: 'Show SettingsPrivateBackup for current device',
      value: `SELECT * FROM SettingsPrivateBackup where device='$beacon_selection' ORDER BY time desc LIMIT 11`,
    },
    {
      label: 'Show SettingsSignatureBackup for current device',
      value: `SELECT * FROM SettingsSignatureBackup where device='$beacon_selection' ORDER BY time desc LIMIT 11`,
    },
    {
      label: 'Show Last_Boot_Cause for current device',
      value: `SELECT * FROM Last_Boot_Cause where device='$beacon_selection' ORDER BY time desc LIMIT 11`,
    },
  ];
};
