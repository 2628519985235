import * as React from 'react';

import { InlineField, InlineSwitch } from '@grafana/ui';
import { UseFormRegister } from 'react-hook-form';
import { GlobalPublicSettingsFormDTO, IndicatorProps } from 'types/form';
import { AppContext } from 'components/SimplePanel';

interface SubSectionPublicProps {
  register: UseFormRegister<GlobalPublicSettingsFormDTO>;
  defaultValues: IndicatorProps;
}

export const SubSectionActivation: React.FunctionComponent<SubSectionPublicProps> = ({ register, defaultValues }) => {
  const { dico } = React.useContext(AppContext);
  const { humidity, temperature } = dico.dico || {};

  const machineDrift_txt = 'Machine Drift';

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '6px',
        }}>
        {/* Humidity */}
        <InlineField label={humidity} labelWidth={24} htmlFor="syncHumidity">
          <InlineSwitch
            {...register('Humidity.enabled')}
            defaultChecked={defaultValues?.Humidity?.enabled}
            id={'syncHumidity'}
          />
        </InlineField>
        {/* Temperature */}
        <InlineField label={temperature} labelWidth={24} htmlFor="syncTemperature">
          <InlineSwitch
            {...register('Temperature.enabled')}
            defaultChecked={defaultValues?.Temperature?.enabled}
            id={'syncTemperature'}
          />
        </InlineField>
        {/* Machine Drift */}
        <InlineField label={machineDrift_txt} labelWidth={24} htmlFor="syncMachineDrift">
          <InlineSwitch
            {...register('MachineDrift.enabled')}
            defaultChecked={defaultValues?.MachineDrift?.enabled}
            id={'syncMachineDrift'}
          />
        </InlineField>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        {/* PT100*/}
        <InlineField label={'PT100'} labelWidth={24} htmlFor="syncPt100">
          <InlineSwitch
            {...register('Pt100.enabled')}
            defaultChecked={defaultValues?.Pt100?.enabled}
            id={'syncPt100'}
          />
        </InlineField>
        {/* TC  */}
        <InlineField label={'TC'} labelWidth={24} htmlFor="syncTC">
          <InlineSwitch {...register('TC.enabled')} defaultChecked={defaultValues?.TC?.enabled} id={'syncTC'} />
        </InlineField>
        {/* Current Loop  */}
        <InlineField label={'Current Loop'} labelWidth={24} htmlFor="syncCurrentLoop">
          <InlineSwitch
            {...register('CurrentLoop.enabled')}
            defaultChecked={defaultValues?.CurrentLoop?.enabled}
            id={'syncCurrentLoop'}
          />
        </InlineField>
      </div>
    </div>
  );
};
