import React from 'react';
import { Card, TagList } from '@grafana/ui';
import { AppContext } from 'components/SimplePanel';

interface BeaconVersionProps {
  version: string;
}

export const BeaconVersion: React.FunctionComponent<BeaconVersionProps> = ({ version }) => {
  const { dico } = React.useContext(AppContext);
  const { noVersionAvailable } = dico?.dico || {};

  return (
    <Card disabled={true}>
      <Card.Heading>Version </Card.Heading>
      <Card.Tags>
        <TagList tags={[version ? version : noVersionAvailable]} />
      </Card.Tags>
    </Card>
  );
};
