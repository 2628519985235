import * as React from 'react';

import { InlineField, Input, InputControl, RadioButtonGroup, Select } from '@grafana/ui';
import { AppContext } from 'components/SimplePanel';
import { handleSensorTypeChange, validateValue } from 'utils/formEvents';
import { SensorType, SubSectionProps } from 'types/form';

import { ACCELERO_MAX_FREQ, MICRO_MAX_FREQ, MIN_INTERVAL_ACCEL, MIN_INTERVAL_MICRO } from 'utils/constants';
import { getSensorTypes, getSignatureSensorOrientations, getSpectrumTypes } from 'utils/formOptions';

export const SubSectionCustomSpectrogram: React.FunctionComponent<SubSectionProps> = ({
  watch,
  setValue,
  getValues,
  setError,
  clearErrors,
  errors,
  control,
}) => {
  const { dico } = React.useContext(AppContext);
  const { frequencyMax, frequencyMin, sensorType, orientation, spectrumType, spectrumTypePlaceholder } =
    dico.dico || {};

  const rms_txt = 'RMS';

  const SENSOR_TYPE_OPTIONS = getSensorTypes(dico);
  const ORIENTATION_OPTIONS = getSignatureSensorOrientations();
  const SPECTRUM_TYPE_OPTIONS_ACC = getSpectrumTypes(dico);
  const SPECTRUM_TYPES_OPTIONS_MICRO = getSpectrumTypes(dico).filter((spectrum) => spectrum.label === rms_txt);

  return (
    <>
      {/* Sensor type */}
      <InlineField label={sensorType} labelWidth={24} htmlFor="sensor_type">
        <InputControl
          render={({ field: { ref, ...field } }) => (
            <RadioButtonGroup
              {...field}
              onChange={(e) => handleSensorTypeChange(e, setValue, clearErrors)}
              options={SENSOR_TYPE_OPTIONS}
              id="sensor_type"
            />
          )}
          control={control}
          name="sensor_type"
        />
      </InlineField>
      {/* Sensor orientation*/}
      {watch('sensor_type') !== SensorType.microphone && (
        <InlineField label={orientation} labelWidth={24} htmlFor="sensorOrientation">
          <InputControl
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                options={ORIENTATION_OPTIONS}
                onChange={(e) => e.value && setValue('sensor_orientation', e.value)}
                inputId="sensorOrientation"
                width={16}
              />
            )}
            control={control}
            name="sensor_orientation"
          />
        </InlineField>
      )}
      {/* Accelerometer */}
      {watch('sensor_type') === SensorType.accelerometer && (
        <>
          {/* Accelerometer min freq */}
          <InlineField
            label={frequencyMin}
            labelWidth={24}
            htmlFor="accFreqMin"
            required
            invalid={!!errors.accelero_freq_min}
            error={errors.accelero_freq_min?.message}>
            <InputControl
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  id="accFreqMin"
                  min={0}
                  max={ACCELERO_MAX_FREQ - MIN_INTERVAL_ACCEL}
                  suffix={'Hz'}
                  type={'number'}
                  width={16}
                  invalid={invalid}
                  required
                  onChange={(e) =>
                    validateValue(
                      'accelero_freq_min',
                      e.currentTarget.value,
                      getValues,
                      setValue,
                      setError,
                      clearErrors,
                      dico.dico
                    )
                  }
                />
              )}
              control={control}
              name="accelero_freq_min"
            />
          </InlineField>
          {/*Accelerometer max freq */}
          <InlineField
            label={frequencyMax}
            labelWidth={24}
            htmlFor="accfreqMax"
            tooltip={`Max ${ACCELERO_MAX_FREQ}Hz`}
            required
            invalid={!!errors.accelero_freq_max}
            error={errors.accelero_freq_max?.message}>
            <InputControl
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  id="accfreqMax"
                  min={0}
                  max={ACCELERO_MAX_FREQ}
                  suffix={'Hz'}
                  type={'number'}
                  width={16}
                  invalid={invalid}
                  required
                  onChange={(e) =>
                    validateValue(
                      'accelero_freq_max',
                      e.currentTarget.value,
                      getValues,
                      setValue,
                      setError,
                      clearErrors,
                      dico.dico
                    )
                  }
                />
              )}
              control={control}
              name="accelero_freq_max"
            />
          </InlineField>
        </>
      )}
      {/* Microphone */}
      {watch('sensor_type') === SensorType.microphone && (
        <>
          {/* Microphone min freq */}
          <InlineField
            label={frequencyMin}
            labelWidth={24}
            htmlFor="micFreqMin"
            required
            invalid={!!errors.micro_freq_min}
            error={errors.micro_freq_min?.message}>
            <InputControl
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  id="micFreqMin"
                  type={'number'}
                  width={16}
                  invalid={invalid}
                  suffix={'Hz'}
                  min={0}
                  required
                  onChange={(e) =>
                    validateValue(
                      'micro_freq_min',
                      e.currentTarget.value,
                      getValues,
                      setValue,
                      setError,
                      clearErrors,
                      dico.dico
                    )
                  }
                />
              )}
              control={control}
              name="micro_freq_min"
            />
          </InlineField>
          {/*Microphone max freq */}
          <InlineField
            label={frequencyMax}
            labelWidth={24}
            htmlFor="micfreqMax"
            tooltip={`Max ${MICRO_MAX_FREQ}Hz`}
            required
            invalid={!!errors.micro_freq_max}
            error={errors.micro_freq_max?.message}>
            <InputControl
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  id="micfreqMax"
                  type={'number'}
                  width={16}
                  invalid={invalid}
                  suffix={'Hz'}
                  min={MIN_INTERVAL_MICRO}
                  max={MICRO_MAX_FREQ}
                  required
                  onChange={(e) =>
                    validateValue(
                      'micro_freq_max',
                      e.currentTarget.value,
                      getValues,
                      setValue,
                      setError,
                      clearErrors,
                      dico.dico
                    )
                  }
                />
              )}
              control={control}
              name="micro_freq_max"
            />
          </InlineField>
        </>
      )}
      {/* Spectrum types */}
      <InlineField label={spectrumType} labelWidth={24} htmlFor="spectrumTypes">
        <InputControl
          render={({ field: { ref, ...field } }) => (
            <Select
              {...field}
              options={
                watch('sensor_type') === SensorType.microphone
                  ? SPECTRUM_TYPES_OPTIONS_MICRO
                  : SPECTRUM_TYPE_OPTIONS_ACC
              }
              onChange={(e) => e.value && setValue('custom_spectrum_type', e.value)}
              inputId="spectrumTypes"
              width={40}
              maxMenuHeight={200}
              placeholder={spectrumTypePlaceholder}
            />
          )}
          control={control}
          name="custom_spectrum_type"
        />
      </InlineField>
    </>
  );
};
