import React from 'react';
import { ButtonCascader, Input, Table, Button } from '@grafana/ui';
import { useAdvancedQueries } from 'hooks/useAdvancedQueries';

interface AdvancedQueriesProps {
  width: number;
  height: number;
}

export const AdvancedQueries: React.FunctionComponent<AdvancedQueriesProps> = ({ width, height }) => {
  const {
    getData,
    handleInputChange,
    handleSelectOption,
    query,
    queryStatus,
    ref,
    selectionQueriesOptions,
    tableData,
    tableTitle,
  } = useAdvancedQueries();

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          marginBottom: '15px',
        }}>
        <ButtonCascader
          variant="secondary"
          icon="bookmark"
          onChange={(e) => handleSelectOption(e)}
          options={selectionQueriesOptions}>
          Queries
        </ButtonCascader>
        <Input
          value={query}
          onChange={(e) => handleInputChange(e.currentTarget.value)}
          onKeyPress={(e) => e.key === 'Enter' && getData()}
          placeholder={'Select a query and press enter'}
          ref={ref}
        />
        <Button icon="search" onClick={() => getData()}></Button>
      </div>
      {queryStatus && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            fontSize: '12px',
            padding: '10px',
            backgroundColor: '#ffebeb7a',
          }}>
          {queryStatus}
        </div>
      )}
      <h4>{tableTitle}</h4>
      {tableData && tableData.length !== 0 && <TableResults tableData={tableData} width={width} height={height} />}
    </div>
  );
};

interface TableProps {
  tableData: any;
  width: number;
  height: number;
}

const TableResults: React.FunctionComponent<TableProps> = ({ tableData, width, height }) => {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <Table width={width} height={height - height / 4} data={tableData} />
    </div>
  );
};
