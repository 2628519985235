import React, { useState } from 'react';
import { AppEvents } from '@grafana/data';
import { getBackendSrv, getAppEvents } from '@grafana/runtime';

import { AppContext } from 'components/SimplePanel';
import { getNumberOptions, getSecondsOptions } from 'utils/formOptions';
import { UseFormClearErrors, UseFormGetValues, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { PeriodName, GlobalPublicSettingsFormDTO } from 'types/form';
import { MsgMapOpcodes } from 'utils/settings_models';
import { buildPublicSettingsValue, triggerSettingsExpedition } from 'utils/helpers';
import { useSettingsPublic } from './useSettingsPublic';

type RequestSettingsType = 'reboot' | 'send' | 'request';

interface SettingsPayload {
  client: string;
  mac_address: string;
  command: number;
  gateway: string;
  settings: string;
}

export const useFormPublicSettings = (setIsMainModalOpen: (value: boolean) => void) => {
  const { beacon, datasource, client, gateway, isVersionFound, setIsVersionFound, user, dico } =
    React.useContext(AppContext);
  const { noClient, errorIndicators, errorSendingPublicSettings } = dico?.dico || {};

  const settings = useSettingsPublic(setIsVersionFound);

  const defaultSync = settings?.public?.ambient_periodicity === settings?.public?.prediction_periodicity;

  const [isSync, setIsSync] = useState(defaultSync);
  const [showConfirmModalSend, setShowConfirmModalSend] = React.useState(false);
  const [showConfirmModalReboot, setShowConfirmModalReboot] = React.useState(false);

  const [settingsForm, setSettingsForm] = React.useState({} as any);

  const appEvents = getAppEvents();

  // endpoint
  const WRITE_API_URL = `api/datasources/proxy/uid/${datasource.dashGen.uid}`;
  const STORE_SETTINGS_ENDPOINT = `${WRITE_API_URL}/store_settings`;

  const HOURS = getNumberOptions(0, 7);
  const MINUTES = getNumberOptions(0, 60);
  const SECONDS = getSecondsOptions();

  React.useEffect(() => {
    if (isVersionFound && settings?.public?.ambient_periodicity === settings?.public?.prediction_periodicity) {
      setIsSync(true);
    }
  }, [settings, isVersionFound]);

  /**
   * Synchronize ambient and prediction period state
   * @param currentValue
   * @param setValue
   * @param getValues
   * @param name form element that is changed
   * @param syncName form element to by synchronized
   */

  const handlePeriodChange = (
    currentValue: number,
    setValue: UseFormSetValue<GlobalPublicSettingsFormDTO>,
    getValues: UseFormGetValues<GlobalPublicSettingsFormDTO>,
    setError: UseFormSetError<GlobalPublicSettingsFormDTO>,
    clearErrors: UseFormClearErrors<GlobalPublicSettingsFormDTO>,
    name: PeriodName,
    syncName: PeriodName
  ) => {
    clearErrors();
    if (isSync) {
      setValue(syncName, currentValue);
    }
    setValue(name, currentValue);
    if (
      getValues('ambient_hours') === 0 &&
      getValues('ambient_minutes') === 0 &&
      getValues('prediction_hours') === 0 &&
      getValues('prediction_minutes') === 0
    ) {
      if (user.orgRole === 'Admin' && getValues('ambient_seconds') === 0 && getValues('prediction_seconds') === 0) {
        setError(name, {
          type: 'custom',
          message: errorIndicators,
        });
        return;
      }
      setError(name, {
        type: 'custom',
        message: errorIndicators,
      });
    }
  };

  /**
   * Sync ambient period values and prediction period values on switch change
   * @param setValue
   * @param getValues
   */

  const handleSwitch = (
    setValue: UseFormSetValue<GlobalPublicSettingsFormDTO>,
    getValues: UseFormGetValues<GlobalPublicSettingsFormDTO>,
    setError: UseFormSetError<GlobalPublicSettingsFormDTO>
  ) => {
    if (!isSync) {
      const currentAmbientHours = getValues('ambient_hours');
      const currentAmbientMinutes = getValues('ambient_minutes');
      const currentAmbientSeconds = getValues('ambient_seconds');
      if (currentAmbientHours === 0 && currentAmbientMinutes === 0 && currentAmbientSeconds === 0) {
        setError('ambient_minutes', {
          type: 'custom',
          message: errorIndicators,
        });
      }
      setValue('prediction_hours', currentAmbientHours);
      setValue('prediction_minutes', currentAmbientMinutes);
      setValue('prediction_seconds', currentAmbientSeconds);
    }
    setIsSync(!isSync);
  };

  const handleConfirmModal = (requestType: RequestSettingsType) => {
    if (!client || !gateway || !beacon) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [noClient],
      });
      return;
    }

    switch (requestType) {
      case 'send':
        setShowConfirmModalSend(true);
        break;

      case 'reboot':
        setShowConfirmModalReboot(true);
        break;

      default:
        break;
    }
  };

  const handleModalClose = (request: RequestSettingsType) => {
    switch (request) {
      case 'send':
        setShowConfirmModalSend(false);
        break;
      case 'reboot':
        setShowConfirmModalReboot(false);
        break;
      case 'request':
        setIsMainModalOpen(false);
        break;
      default:
        break;
    }
  };

  const specialCommandRequest = (requestType: RequestSettingsType, opCode: number) => {
    // Check destination validity
    if (!client || !gateway || !beacon) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [noClient],
      });
      return;
    }

    // Fix for clients containing spaces in their name
    const targetClient = client.replace(/\s/g, '\\ ');

    const settingsPayload = {
      client: targetClient,
      mac_address: beacon,
      command: opCode,
      gateway: gateway,
      settings: '00',
    };

    sendSettings(settingsPayload, requestType);
  };

  /** Build public settings value */
  const getSettingsValue = () => {
    const publicSettingsToSend = buildPublicSettingsValue(settingsForm, dico.dico);
    if (!publicSettingsToSend) {
      return;
    }
    const command = MsgMapOpcodes.RX_PUB_SETTINGS_UPDATE;
    const settingsPayload = {
      client: client,
      mac_address: beacon,
      command: command,
      gateway: gateway,
      settings: publicSettingsToSend?.toUpperCase(),
    };

    sendSettings(settingsPayload, 'send');
  };

  /**
   * Post settings value to SettingsQueue and then in SettingPublicBackup
   * @param settingsPayload
   */

  const sendSettings = async (settingsPayload: SettingsPayload, requestType: RequestSettingsType) => {
    await getBackendSrv()
      .post(STORE_SETTINGS_ENDPOINT, settingsPayload, {
        responseType: 'text', // very crucial to specify responseType (otherwise this generates an error)
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        triggerSettingsExpedition(client, gateway, beacon, datasource.dashGen, dico?.dico).catch((err) => {
          console.log(err);
          handleModalClose(requestType);
        });
        handleModalClose(requestType);
      })
      .catch((err) => {
        console.log(err);
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [errorSendingPublicSettings],
        });
        handleModalClose(requestType);
      });
  };

  return {
    HOURS,
    MINUTES,
    SECONDS,
    defaultSync,
    getSettingsValue,
    handleConfirmModal,
    handlePeriodChange,
    handleSwitch,
    isSync,
    setIsSync,
    setSettingsForm,
    setShowConfirmModalReboot,
    setShowConfirmModalSend,
    settings,
    showConfirmModalReboot,
    showConfirmModalSend,
    specialCommandRequest,
  };
};
