import * as React from 'react';

import { InlineField, Input, InputControl } from '@grafana/ui';

import { SubSectionProps } from 'types/form';
import { validateValue } from 'utils/formEvents';

export const SubSectionRpmSettings: React.FunctionComponent<SubSectionProps> = ({
  setValue,
  getValues,
  setError,
  clearErrors,
  errors,
  control,
  dico,
  register,
}) => {
  const rpm_min_txt = 'RPM min';
  const rpm_max_txt = 'RPM max';

  return (
    <>
      {/* RPM min */}
      <InlineField
        label={rpm_min_txt}
        labelWidth={24}
        htmlFor="rpmMin"
        required
        invalid={!!errors.rpm_min}
        error={errors.rpm_min?.message}
        validationMessageHorizontalOverflow>
        <InputControl
          render={({ field, fieldState: { invalid } }) => (
            <Input
              {...field}
              invalid={invalid}
              width={16}
              id="rpmMin"
              required
              type={'number'}
              suffix={'rpm'}
              min={0}
              {...register('rpm_min', {
                required: dico.fieldRequired,
              })}
              onChange={(e) =>
                validateValue('rpm_min', e.currentTarget.value, getValues, setValue, setError, clearErrors, dico)
              }
            />
          )}
          control={control}
          name="rpm_min"
        />
      </InlineField>
      {/* RPM max */}
      <InlineField
        label={rpm_max_txt}
        labelWidth={24}
        htmlFor="rpmMax"
        required
        invalid={!!errors.rpm_max}
        error={errors?.rpm_max?.message}
        validationMessageHorizontalOverflow>
        <InputControl
          render={({ field, fieldState: { invalid } }) => (
            <Input
              {...field}
              invalid={invalid}
              width={16}
              id="rpmMax"
              type={'number'}
              min={0}
              suffix={'rpm'}
              {...register('rpm_max', {
                required: dico.fieldRequired,
              })}
              onChange={(e) =>
                validateValue('rpm_max', e.currentTarget.value, getValues, setValue, setError, clearErrors, dico)
              }
            />
          )}
          control={control}
          name="rpm_max"
        />
      </InlineField>
    </>
  );
};
