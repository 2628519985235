import React, { useCallback } from 'react';
import { PanelData, getProcessedDataFrames } from '@grafana/data';
import { SETTINGS_VERSION } from 'utils/constants';

/**
 * Get memoized beacon version value.
 * Default 0.0
 * @param panelData
 * @returns { displayed: string, raw: number }
 */

export const useVersion = (panelData: PanelData) => {
  const getVersion = useCallback(() => {
    if (!panelData?.series?.length) {
      console.log('No data at all. Can not get beacon version.');
      return { displayed: '', raw: 0.0 };
    }

    const data = getProcessedDataFrames(panelData?.series);
    const publicSettingsData = data.filter((d) => d.name === SETTINGS_VERSION);

    if (!publicSettingsData.length) {
      console.log('No Public settings found. Needed for getting the version.');
      return { displayed: '', raw: 0.0 };
    }

    const version = publicSettingsData[0].fields[1].values.get(0);
    const raw = parseFloat(version.replace('v', ''));
    return { displayed: version, raw: raw };
  }, [panelData]);

  const version = React.useMemo(() => getVersion(), [getVersion]);
  return version;
};
