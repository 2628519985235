import React from 'react';
import { toDataFrame, MutableDataFrame, FieldType, DisplayProcessor } from '@grafana/data';
import { getBackendSrv, getTemplateSrv } from '@grafana/runtime';
import { getQueryTemplates } from 'utils/advanced';
import { AppContext } from 'components/SimplePanel';

export const useAdvancedQueries = () => {
  const { datasource } = React.useContext(AppContext);
  const [query, setQuery] = React.useState('');
  const [results, setResults] = React.useState<any[]>([]);
  const [tableTitle, setTableTitle] = React.useState('');
  const [queryStatus, setQueryStatus] = React.useState('');

  const ref = React.useRef<HTMLInputElement>(null);

  const selectionQueriesOptions = React.useMemo(() => {
    return getQueryTemplates();
  }, []);

  const handleSelectOption = (e: string[]) => {
    setResults([]);
    setQuery(e[0]);
    setTableTitle('');
    setQueryStatus('');
    ref!.current!.focus();
  };

  const handleInputChange = (e: string) => {
    setResults([]);
    setTableTitle('');
    setQueryStatus('');
    setQuery(e);
  };

  const getData = () => {
    if (!query) {
      setResults([]);
      setTableTitle('');
      setQueryStatus('No query.');
      return;
    }

    const abortController = new AbortController();
    const fetchData = async () => {
      let queryToSend = query;
      if (query.includes('$beacon_selection')) {
        queryToSend = getTemplateSrv().replace(query);
      }
      const urlQuery = `api/datasources/proxy/uid/${datasource.influx?.uid}/query?db=${datasource.influx.name}&q=${queryToSend}`;
      try {
        const results = await getBackendSrv().get(urlQuery);
        setResults(results.results);
      } catch (error: any) {
        setResults([]);
        setTableTitle('');
        setQueryStatus(
          `Error while requesting datasource. ${error?.status && `Status: ${error.status}`}${
            error?.statusText && `, ${error.statusText}.`
          } ${error?.data?.message && `Message: ${error.data.message}.`}`
        );
        console.log(error);
      }
    };

    if (query) {
      fetchData();
    }

    return () => {
      abortController.abort();
    };
  };

  const getDataTable = () => {
    if (results?.length) {
      if (results[0].series?.length) {
        setTableTitle(results[0].series[0].name);

        const resultFrame = new MutableDataFrame();
        const display: DisplayProcessor = (v) => ({
          numeric: Number(v),
          text: String(v),
          color: 'red',
        });

        for (const serie of results[0].series) {
          for (const [indexCol, col] of serie.columns.entries()) {
            resultFrame.addField({
              config: {},
              name: col,
              values: serie.values.map((val: any[]) => val[indexCol]),
              display,
              type: col === 'time' ? FieldType.time : FieldType.string,
            });
          }
        }
        return toDataFrame(resultFrame);
      } else {
        setQueryStatus('No result');
      }
      return [];
    }
    return [];
  };

  const tableData = React.useMemo(
    () => getDataTable(),
    [results] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    getData,
    handleInputChange,
    handleSelectOption,
    query,
    queryStatus,
    ref,
    selectionQueriesOptions,
    tableData,
    tableTitle,
  };
};
