import React from 'react';
import { AppEvents } from '@grafana/data';
import { getDataSourceSrv, getAppEvents } from '@grafana/runtime';
import { DASH_GEN, INFLUX_DB } from 'utils/constants';

/**
 * Get datasource.
 */

export const useDatasource = () => {
  const [datasource, setDatasource] = React.useState({
    influx: {} as any,
    dashGen: {} as any,
  });

  React.useEffect(() => {
    const abortController = new AbortController();
    const appEvents = getAppEvents();

    const fetchData = async () => {
      try {
        const influx = await getDataSourceSrv().get(INFLUX_DB);
        const dashGen = await getDataSourceSrv().get(DASH_GEN);
        setDatasource({
          influx: influx,
          dashGen: dashGen,
        });
      } catch (error: any) {
        console.log(error);
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [`Datasources not found`],
        });
      }
    };
    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  return datasource;
};
