import * as React from 'react';

import { getBackendSrv, getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import { AppContext } from 'components/SimplePanel';

import { useSettingsPrivate } from 'hooks/useSettingsPrivate';
import { useVersion } from 'hooks/useVersion';
import { MsgMapOpcodes } from 'utils/settings_models';
import { buildPrivateSettingsValue, triggerSettingsExpedition } from 'utils/helpers';
import { SignatureFormDTO } from 'types/form';

export const useFormSectionSignatureSettings = () => {
  const { panelData, datasource, beacon, client, gateway, setIsVersionFound, isVersionFound, dico } =
    React.useContext(AppContext);
  const { noClient, errorSignatureSettings, settingsEmpty } = dico?.dico || {};

  const settings = useSettingsPrivate(panelData, setIsVersionFound, isVersionFound);
  const version = useVersion(panelData);
  const canUseCustomSpectrogram = version.raw >= 4.45;

  // endpoint
  const WRITE_API_URL = `api/datasources/proxy/uid/${datasource.dashGen.uid}`;
  const STORE_SETTINGS_ENDPOINT = `${WRITE_API_URL}/store_settings`;

  const appEvents = getAppEvents();

  /** Build Private settings value */
  const confirmSettings = (form: SignatureFormDTO) => {
    if (!Object.keys(form).length) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [settingsEmpty],
      });
      return;
    }

    if (!client || !gateway || !beacon) {
      appEvents.publish({
        type: AppEvents.alertError.name,
        payload: [noClient],
      });
      return;
    }

    const privateSettingsBuiltvalue = buildPrivateSettingsValue(form, dico.dico);
    if (!privateSettingsBuiltvalue) {
      return;
    }
    sendSettings(privateSettingsBuiltvalue);
  };

  /**
   * Post settings value to SettingsQueue and then in SettingPrivateBackup
   * @param privateSettingsToSend
   */

  const sendSettings = async (privateSettingsToSend: string) => {
    const COMMAND = MsgMapOpcodes.RX_PRV_SETTINGS_UPDATE;

    const settingsPayload = {
      client: client,
      mac_address: beacon,
      command: COMMAND,
      gateway: gateway,
      settings: privateSettingsToSend?.toUpperCase(),
    };

    await getBackendSrv()
      .post(STORE_SETTINGS_ENDPOINT, settingsPayload, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        triggerSettingsExpedition(client, gateway, beacon, datasource.dashGen, dico?.dico);
      })
      .catch((err) => {
        console.log(err);
        appEvents.publish({
          type: AppEvents.alertError.name,
          payload: [errorSignatureSettings],
        });
      });
  };

  return {
    canUseCustomSpectrogram,
    confirmSettings,
    settings,
  };
};
