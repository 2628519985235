import * as React from 'react';

import { InlineField, InlineSwitch, InputControl, Input } from '@grafana/ui';
import { SubSectionRadioProps } from 'types/form';
import { validateValue } from 'utils/formEvents';

export const SubSectionRadio: React.FunctionComponent<SubSectionRadioProps> = ({
  setValue,
  setError,
  clearErrors,
  getValues,
  register,
  defaultValues,
  errors,
  control,
  dico,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '6px',
        }}>
        {/* ADR Enabled */}
        <InlineField label={'ADR Enabled'} labelWidth={24} htmlFor="syncAdr">
          <InlineSwitch {...register('radio_adr')} defaultChecked={defaultValues?.radio_adr} id={'syncAdr'} />
        </InlineField>
        {/* Txack Enabled */}
        <InlineField label={'Txack Enabled'} labelWidth={24} htmlFor="syncTxack">
          <InlineSwitch {...register('radio_txack')} defaultChecked={defaultValues?.radio_txack} id={'syncTxack'} />
        </InlineField>
        {/* Private NW (0x12) */}
        <InlineField label={'Private NW (0x12)'} labelWidth={24} htmlFor="syncPrivateNw">
          <InlineSwitch
            {...register('radio_nw_private')}
            defaultChecked={defaultValues?.radio_nw_private}
            id={'syncPrivateNw'}
          />
        </InlineField>
        {/* PT100*/}
        <InlineField label={'Force CR4/5'} labelWidth={24} htmlFor="syncForceCR">
          <InlineSwitch
            {...register('radio_cr_base_lorawan')}
            defaultChecked={defaultValues?.radio_cr_base_lorawan}
            id={'syncForceCR'}
          />
        </InlineField>
        {/* Dwell Time ON */}
        <InlineField label={'Dwell Time ON'} labelWidth={24} htmlFor="syncDwellTime">
          <InlineSwitch
            {...register('radio_dwell_time')}
            defaultChecked={defaultValues?.radio_dwell_time}
            id={'syncDwellTime'}
          />
        </InlineField>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <InlineField label={'Retx enabled (x2)'} labelWidth={24} htmlFor="syncRetx">
          <InlineSwitch
            {...register('radio_retx_twice')}
            defaultChecked={defaultValues?.radio_retx_twice}
            id={'syncRetx'}
          />
        </InlineField>
        {/* Dwell Time ON */}
        <InlineField label={'Enable packet split'} labelWidth={24} htmlFor="syncLowestDr">
          <InlineSwitch
            {...register('radio_force_lowest_dr')}
            defaultChecked={defaultValues?.radio_force_lowest_dr}
            id={'syncLowestDr'}
          />
        </InlineField>
        <InlineField
          label={'LoRa Freq Param'}
          labelWidth={24}
          htmlFor="loraFreqParam"
          required
          tooltip={'EU: N/A, US: FSB index [0..8], AS: Country preset [0..11]'}
          invalid={!!errors.radio_region_param}
          error={errors.radio_region_param?.message}>
          <InputControl
            render={({ field, fieldState: { invalid } }) => (
              <Input
                {...field}
                id="loraFreqParam"
                type={'number'}
                width={16}
                invalid={invalid}
                min={0}
                {...register('radio_region_param', {
                  required: dico.fieldRequired,
                })}
                onChange={(e) =>
                  validateValue(
                    'radio_region_param',
                    e.currentTarget.value,
                    getValues,
                    setValue,
                    setError,
                    clearErrors,
                    dico
                  )
                }
              />
            )}
            control={control}
            name="radio_region_param"
          />
        </InlineField>
        <InlineField
          label={'LoRa LinkChk'}
          labelWidth={24}
          htmlFor="linkCheck"
          required
          invalid={!!errors.radio_linkchk}
          error={errors.radio_linkchk?.message}>
          <InputControl
            render={({ field, fieldState: { invalid } }) => (
              <Input
                {...field}
                id="linkCheck"
                type={'number'}
                width={16}
                invalid={invalid}
                suffix={'s'}
                min={0}
                required
                {...register('radio_linkchk', {
                  required: dico.fieldRequired,
                })}
                onChange={(e) =>
                  validateValue(
                    'radio_linkchk',
                    e.currentTarget.value,
                    getValues,
                    setValue,
                    setError,
                    clearErrors,
                    dico
                  )
                }
              />
            )}
            control={control}
            name="radio_linkchk"
          />
        </InlineField>
      </div>
    </div>
  );
};
